<div class="main-content">
    <mat-toolbar>
        <mat-toolbar-row >
            <button mat-icon-button (click)="toggleConversationDrawer()"><mat-icon>menu</mat-icon></button>
            <div>
                <img class="logo" src="img/BKW_Logo_RGB_S.png" width="142" height="64" />
            </div>
            <div class="app-title">
                {{ appTitle$ | async }}
            </div>
            <button mat-icon-button class='tenant-admin-button' *ngIf="((activeTenant$ | async)?.access || 0) > 0" [routerLink]="'/tenant/'+(activeGroupId$ | async)+'/admin'">
             <mat-icon iconPositionEnd>settings</mat-icon>
            </button>
            <span style="flex: 1 1 auto;"></span>
            <div layout="row" layout-align="center center">
                <span class="user-name" *ngIf="(viewMode$ |async) == 'desktop'">{{(account | async)?.name }}</span><img class="avatar-img" [src]="(userAvatar$ | async)"/>
                <app-changelog></app-changelog>
                <button mat-icon-button [matMenuTriggerFor]="menu" class="more-menu" aria-label="Menu to switch language and tenants">
                    <mat-icon iconPositionEnd>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu" xPosition="before">
                    <button mat-menu-item [matMenuTriggerFor]="groups">{{ 'GROUP.TITLE' | translate }}</button>
                    <button mat-menu-item [matMenuTriggerFor]="languages">{{ 'LANG.TITLE' | translate }}</button>
                    <button mat-menu-item (click)="showComplianceDialog()">{{ 'COMPLIANCE.TC' | translate }}</button>
                    <div class="ui-version">ui: v{{version}}</div>
                </mat-menu>
                <mat-menu #admin="matMenu">
                    <ng-template matMenuContent let-id="id" let-access="access">
                        <button mat-menu-item [routerLink]="'/tenant/'+id+'/admin'">{{ 'ADMIN.TITLE' | translate }}</button>
                    </ng-template>
                </mat-menu>
                <mat-menu #groups="matMenu">
                    @for (item of tenants$ | async ; track item.id) {
                        <button mat-menu-item [matMenuTriggerFor]="item.access > 1 ? null : null" [matMenuTriggerData]="{id: item.id, access: item.access}" [routerLink]="'/tenant/'+item.id+'/conversation'"><mat-icon>{{item.id == (activeGroupId$ | async) ? "check_small" : ""}}</mat-icon>{{ item.name }}</button>
                    } @empty {
                        <li>{{ "GROUP.NO_GROUP" | translate }}</li>
                    }
                </mat-menu>
                <mat-menu #languages="matMenu">
                    @for (lang of langs ; track lang) {
                        <button mat-menu-item (click)="setLang(lang)"><mat-icon>{{(translate.currentLang || translate.defaultLang) == lang ? "check_small": ""}}</mat-icon>{{ 'LANG.' + lang | translate }}</button>
                    }
                </mat-menu>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>
    <router-outlet (deactivate)="onDestroy($event)"></router-outlet>

</div>
