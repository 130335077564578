import {isDevMode} from '@angular/core';
import {ActionReducerMap, MetaReducer} from '@ngrx/store';
import * as fromGroup from './group.reducer';
import * as fromApp from './app.reducer';
import * as fromPromptlab from './prompt-lab.reducer';
import * as fromConversations from './conversations.reducer'
import * as fromPermissions from './permissions.reducer'
import {routerReducer, RouterReducerState} from "@ngrx/router-store";


export interface State {
    groups: fromGroup.GroupState
    conversations: fromConversations.ConversationsState
    app: fromApp.AppState
    router: RouterReducerState
    promptlab: fromPromptlab.PromptlabState,
    permissions: fromPermissions.PermissionState
}

export const reducers: ActionReducerMap<State> = {
    groups: fromGroup.reducer,
    conversations: fromConversations.reducer,
    app: fromApp.reducer,
    router: routerReducer,
    promptlab: fromPromptlab.reducer,
    permissions: fromPermissions.reducer
};


export const metaReducers: MetaReducer<State>[] = isDevMode() ? [] : [];
