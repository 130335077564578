import {Component, inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {Tenant} from "../../model/group.model";
import {TranslateService} from "@ngx-translate/core";


@Component({
    selector: 'app-compliance-dialog',
    templateUrl: './compliance-dialog.component.html',
    styleUrl: './compliance-dialog.component.scss',
    standalone: false
})
export class ComplianceDialogComponent {
  readonly data = inject<Tenant>(MAT_DIALOG_DATA);
  constructor(protected translate: TranslateService) {
  }

}
