import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {KatexOptions} from "ngx-markdown";
import {ConversationElement} from "../../model/conversation-element.model";
import {activePrompt, activeTenant} from "../../redux/reducers/group.reducer";
import {State} from "../../redux/reducers";
import {Store} from "@ngrx/store";
import {Clipboard} from "@angular/cdk/clipboard";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ChatMessage, Rating, RetrievedDocument} from "../../model/chat-message";
import {Topic} from "../../model/group.model";
import {Observable, of, Subject} from "rxjs";
import {selectPromptByLabel} from "../../redux/reducers/conversations.reducer";
import {Prompt} from "../../model/prompt.model";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-chat-answer-card',
  standalone: false,
  
  templateUrl: './chat-answer-card.component.html',
  styleUrl: './chat-answer-card.component.scss',
  changeDetection: ChangeDetectionStrategy.Default
})
export class ChatAnswerCardComponent {

  _element: ConversationElement | undefined = undefined;
  @Input() set element(value: ConversationElement | undefined) {
    this._element = value;
    this.feedbackForm = new FormGroup({
      rating: new FormControl<number>(value?.feedback?.rating || 0, [Validators.required, Validators.min(1), Validators.max(6)]),
      comment: new FormControl<string>(value?.feedback?.comment || '')
    });
  }

  protected _text: string | undefined = undefined;
  @Input() text: Observable<ChatMessage> | undefined = undefined
  @Input() ongoing = false;
  @Input() feedbackEnabled = false;
  @Input() infoEnabled = false;
  @Output() onFeedback = new EventEmitter<Rating>();
  public katexOptions: KatexOptions = {
    displayMode: true,
    throwOnError: false,
    errorColor: '#cc0000'
  };
  protected showInfo = false;
  protected showFeedback = false;
  protected visibleSourceTopic  = "";
  protected rate = 4;

  activePrompt$ = this.store.select(activePrompt)
  activeTenant$ = this.store.select(activeTenant)
  feedbackForm = new FormGroup({
    rating: new FormControl<number>(0, [Validators.required, Validators.min(1), Validators.max(6)]),
    comment: new FormControl<string>('')
  });


  constructor(private changeDetector: ChangeDetectorRef, private store: Store<State>, private clipboard: Clipboard, private snackBar: MatSnackBar) {
  }

  sanitizeContent(input: string){
    let sanitized =  input ? input.replace(/(\\\[)/g,'\\\\\[') : input;
    sanitized =  sanitized ? sanitized.replace(/(\\\])/g,'\\\\]') : sanitized;
    sanitized =  sanitized ? sanitized.replace(/(\\\()/g,'\\\\(') : sanitized;
    sanitized =  sanitized ? sanitized.replace(/(\\\))/g,'\\\\)') : sanitized;

    return sanitized;
  }

  changeRating(elementId: string){
    console.log("Change rating")
    if (this.feedbackForm.valid){
      this.onFeedback.emit({elementId: elementId, rating: this.feedbackForm.value.rating || 0, comment: this.feedbackForm.value.comment || ''});
      this.showFeedback = false;
    }else {
      console.log("Invalid feedback form")
    }
  }

  copyClipboard(text: string){
    const pending = this.clipboard.beginCopy(text);
    let remainingAttempts = 3;
    const attempt = () => {
      const result = pending.copy();
      if (!result && --remainingAttempts) {
        setTimeout(attempt);
      } else {
        this.snackBar.open("Copied to clipboard", "Dismiss", {duration: 2000})
        pending.destroy();
      }
    };
    attempt();
  }

  groupByTopic(collection: RetrievedDocument[]): any{
    if (collection == undefined) {
      return {};
    }
    const groupedResult =  collection.reduce((previous,current)=>{

      if(!previous[current.topic]){
        previous[current.topic] = [] as RetrievedDocument[];
      }

      previous[current.topic].push(current);
      return previous;
    },{} as any);
    return groupedResult
  }

  toggleSourceTopic(topic: any){
    if(this.visibleSourceTopic == topic){
      this.visibleSourceTopic = "";
    }else{
      this.visibleSourceTopic = topic;
    }
  }

  toggleFeeback() {
    this.showFeedback = !this.showFeedback;
  }

  toggleInfo() {
    this.showInfo = !this.showInfo;
  }

  getPrompt(label: string |undefined){
    if (label != undefined)
      return this.store.select(selectPromptByLabel(label))
    else
      return of({label: "undefined", title: "Undefined Prompt", appearance: {color: 'gray', icon: ''}} as unknown as Prompt)
  }

  castToRetrievedDocument(input: any){
    return input as RetrievedDocument[];
  }

  mapTopicName(topics: Topic[], key: any){
    return topics.filter((topic) => topic.key == key).map((topic) => topic.name)[0]
  }


}
