<mat-drawer-container class="chat-container">
    <mat-drawer [mode]="drawerMode" [opened]="conversationDrawerState$ | async" class="conversation-list">
        <div class="conversation-header">
            <div style="vertical-align: middle;">{{"CONV.TITLE" | translate}}</div>
            <div>
                <app-add-conversation-button [disabled]="(appBusyState$ | async) || false"></app-add-conversation-button>
            </div>
        </div>
        <!-- Show filter buttons when there are any conversations -->
        <mat-button-toggle-group class="conversation-filter" [(ngModel)]="visibilityFilter"
                                 (change)="onVisibilityFilterChange($event.value)"
                                 *ngIf="hasPublicConversations$ | async"
        >
            <mat-button-toggle value="public" class="conversation-filter-button">{{'CONV.PUBLIC' | translate}}</mat-button-toggle>
            <mat-button-toggle value="private" class="conversation-filter-button">{{'CONV.PRIVATE' | translate}}</mat-button-toggle>
            <mat-button-toggle value="all" class="conversation-filter-button">{{'CONV.ALL' | translate}}</mat-button-toggle>
        </mat-button-toggle-group>
        <!-- Wrap all content in a scrollable container div -->
        <div class="conversation-scroll-container" [style]="{'max-height': (hasPublicConversations$ | async)?'calc(100% - 120px)':'calc(100% - 64px)'}" #conversationListContainer (scroll)="loadMoreConversations($event)">

            <mat-nav-list>
                @for (conversation of ((conversations$ |async) || [] | orderConversationBy :'modified_at':'desc'); track conversation.id; let idx = $index) {
                    <!-- Show conversation based on visibility filter or if it's currently active -->
                    <mat-list-item *ngIf="visibilityFilter === 'all' || visibilityFilter === conversation.visibility || (activeConversationId$ | async) === conversation.id" 
                                  [id]="'conv' + conversation.id" 
                                  [activated]="(activeConversationId$ | async) === conversation.id" 
                                  routerLink="../../conversation/{{conversation.id}}">
                            <div matListItemTitle>{{conversation.title || "CONV.UNTITLED" | translate}}</div>
                           <p matListItemLine>{{conversation.modified_at | dateAgo | async }}</p>
                        <p matListItemMeta>
                            <button *ngIf="(activeConversationId$ | async) === conversation.id" 
                                   mat-icon-button 
                                   style="--mat-icon-button-state-layer-color: none;" 
                                   [disableRipple]="false" 
                                   [matMenuTriggerFor]="conversationMenu" 
                                   [matMenuTriggerData]="{id: conversation.id, title: conversation.title}" 
                                   [matMenuTriggerRestoreFocus]="false">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <!-- Show visibility icon for public conversations -->
                            <mat-icon *ngIf="conversation.visibility === 'public'" 
                                     [matTooltip]="'CONV.PUBLIC_TOOLTIP' | translate" 
                                     class="conversation-visibility-icon">groups</mat-icon>
                        </p>
                    </mat-list-item>
                }
                <mat-menu #conversationMenu="matMenu">
                    <ng-template matMenuContent let-id="id" let-title="title">
                        <button mat-menu-item (click)="editTitle($event, id, title);"><mat-icon>edit</mat-icon>
                            {{ 'CONV.EDIT_TITLE' | translate }}</button>
                        <button mat-menu-item (click)="deleteConversation($event, id)"><mat-icon class="delete-icon">delete</mat-icon>
                            {{ 'CONV.DELETE' | translate }}</button>
                    </ng-template>

                </mat-menu>
            </mat-nav-list>
            <!-- Loading indicator shown when loading more conversations -->
            <div *ngIf="isLoadingMore$ | async" class="loading-more-indicator">
                <mat-spinner diameter="24"></mat-spinner>
                <span>{{ 'CONV.LOADING_MORE' | translate }}</span>
            </div>
            
            <!-- Sentinel at the bottom for detecting when to load more -->
            <div class="scroll-sentinel" #scrollSentinel></div>
        </div>

        <div class="feedback-container"><button mat-fab (click)="openFeedbackPanel()">
            <mat-icon>feedback</mat-icon>
        </button></div>
    </mat-drawer>
    <mat-drawer-content class="chat-panel">
        <div class="scroll-container" #chatScrollContainer>
            <div class="chat-conversation">
                <app-chat-answer-card
                    [text]="of({chunk_message: ('CONV.INTRO' | translate), ongoing: false})"
                    [ongoing]="false"
                    *ngIf="(activeConversationId$ | async) && ((conversationDetail$ | async)?.messages || []).length == 0"
                ></app-chat-answer-card>
                @for (element of (conversationDetail$ |async)?.messages; track elementKey(element);) {
                    <mat-card  appearance="outlined" class="chat-user-prompt">
                    <mat-card-content>
                        <p markdown>{{element.question}}</p>
                    </mat-card-content>
                </mat-card>
                    <app-chat-answer-card
                            [element]="element"
                            [feedbackEnabled]="(activeTenant$ | async)?.limits?.hasUserFeedback || false"
                            [infoEnabled]="((prompts$ | async)?.length || 0) > 1"
                            *ngIf="!element.ongoing"
                            (onFeedback)="saveFeedback($event)"
                    ></app-chat-answer-card>
                }
                <ng-container *ngIf="(transientResponse$ | async) as response">
                    <app-chat-answer-card
                            [text]="transientResponse$"
                            *ngIf="response.ongoing"
                            [ongoing]="response.ongoing"
                    ></app-chat-answer-card>
                </ng-container>
            </div>
        </div>
        <app-chat-input-field class="chat-footer-container"
                   (onNewQuestion)="onQuestionSubmit($event)"
                   [conversation]="(conversationDetail$ | async) || undefined"
                   [activeConversationId]="(activeConversationId$ | async) || ''"
                   [activePrompt]="(activePrompt$ | async) || undefined"
                   [activeTopics]="(activeTopics$ | async) || []"
                   [availablePrompts]="(prompts$ | async) || []"
                   [tenant]="(activeTenant$ | async) || undefined"
                   [disabled]="((activeConversationId$ | async)?true:false) && (appBusyState$ | async) || false"
        >
        </app-chat-input-field>

    </mat-drawer-content>
</mat-drawer-container>

