import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ConversationsActions} from "../../redux/actions/conversations.actions";
import {of, take} from "rxjs";
import {Store} from "@ngrx/store";
import {State} from "../../redux/reducers";
import {FileUploadComponent} from "../file-upload/file-upload.component";
import {MatDialog} from "@angular/material/dialog";
import {ConversationDetail} from "../../model/conversation-detail.model";
import {Conversation} from "../../model/conversation.model";
import {Prompt} from "../../model/prompt.model";
import {MatChipSelectionChange} from "@angular/material/chips";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {GroupActions} from "../../redux/actions/group.actions";
import {Tenant, Topic} from "../../model/group.model";
import {GroupState} from "../../redux/reducers/group.reducer";

@Component({
  selector: 'app-chat-input-field',
  standalone: false,
  
  templateUrl: './chat-input-field.component.html',
  styleUrl: './chat-input-field.component.scss'
})
export class ChatInputFieldComponent {

  @Input() disabled = false;
  @Input() tenant: Tenant | undefined;
  @Input() conversation: Conversation | undefined;
  @Input() activeConversationId: string = "";
  @Input() activeTopics: string[] = [];
  @Input() availableTopics: string[] = [];
  @Input() activePrompt: Prompt | undefined;
  @Input() availablePrompts: Prompt[] = [];
  @Output() onNewQuestion = new EventEmitter<string>();

  protected message: string = "";
  protected maxMessageLength = 5000;
  protected showLengthIndicatorAfter = 4000;
  protected topicCount = 0;

  constructor(private store: Store<State>, private dialog: MatDialog) {
  }

  addUserPrompt(message: string){
    this.store.dispatch(ConversationsActions.conversationDetailAddQuestion({question: message}))
    this.onNewQuestion.emit(message);
    this.message = "";
  }

  addFile(){
    let dialogRef = this.dialog.open(FileUploadComponent, {
      panelClass: 'file-upload-panel',
      width: '700px',
      height: '700px',
      data: {conversation$: this.activeConversationId}
    })
  }

  toggleTopic(event: MatChipSelectionChange | MatCheckboxChange, topic: string) {
    if((event instanceof MatChipSelectionChange && event.selected) || (event instanceof MatCheckboxChange && event.checked)){
      this.topicCount++;
      this.store.dispatch(GroupActions.groupsActivateTopic({topicId: topic}))

    }else {
      this.topicCount--;
      this.store.dispatch(GroupActions.groupsDeactivateTopic({topicId: topic}))
    }
  }

  extractTopicName(topics: Topic[], key: string){
    return topics.filter((topic) => key == topic.key).map((topic) => topic.name)[0];
  }

  protected readonly of = of;
}
