import { Limits, Tenant, TermsConditions } from "./group.model";
import { Document, Rating, RetrievedDocument } from "./chat-message";
import { ConversationElement } from "./conversation-element.model";
import { Prompt } from "./prompt.model";
import { Conversation } from "./conversation.model";

export interface PromptConfigDTO {
    llms: string[];
    tenant_topics: string[];
    prompts: string[];
    accessible_by: string[];
    used_by: string[];
}

export interface ConversationDTO {
    messages: ConversationElementDTO[];
    documents: DocumentDTO[];
}

export interface ConversationElementDTO {
    created_at: string;
    id: string;
    question: string;
    answer: string;
    status: string;
    llm: string;
    duration_ms?: number;
    total_tokens?: number;
    run_in_additional_information: string;
    feedback?: number;
    feedback_comment?: string;
    prompt?: Prompt;
    documents: RetrievedDocumentDTO[];
    ongoing?: boolean;
}

export interface RetrievedDocumentDTO {
    url: string,
    title: string,
    topic: string,
    relevance: number,
    search_score: number
}

export interface DocumentDTO {
    "created_at": string,
    "modified_at": string,
    "id": string,
    "title": string,
    "description": string,
    "url": string,
    "metadata": string,
    "status": string,
    "classification": string
}

export interface PromptDTO {
    created_at: string;
    modified_at: string;
    id: string;
    title: string;
    label: string;
    accessible_by: string;
    used_by: string;
    characteristic: string;
    available_topics: string;
    default_topics: string;
    llm: string;
    prompt_goal: string;
    grounding_toggle: boolean;
    default: boolean;
    schema_out: string;
}

export interface TopicDTO {
    name: string;
    classification: string;
    key: string;
    defaultEnabled: boolean;
}

export interface TermsConditionsDTO {
    key: string;
    version: number;
    canMute: boolean;
    site_url: string;
    isActive: boolean;
}

export interface LimitsDTO {
    has_user_feedback: boolean;
    num_tokens_per_conversation: number;
    ratio_tokens_for_soft_limit: number;
    has_public_conversations_machine: boolean;
    has_public_conversations_user: boolean;
    num_characters_per_message: number;
    num_ad_hoc_documents: number;
    size_ad_hoc_documents: number;
    tokens_ad_hoc_documents: number;
}

export interface PermissionDTO {
    email: string;
    tier: number;
}

export interface TenantDTO {
    id: string;
    name: string;
    access: number;
    label: string;
    url: string;
    default: boolean;
    topics: TopicDTO[];
    tc?: TermsConditionsDTO;
    limits: LimitsDTO;
}

export interface RatingDTO {
    feedback: number;
    feedback_comment: string;
}

export const convertTenantDTO = (dto: TenantDTO): Tenant => {
    return {
        id: dto.id,
        name: dto.name,
        access: dto.access,
        url: dto.url,
        default: dto.default,
        topics: dto.topics,
        tc: convertTermsConditionsDTO(dto.tc),
        limits: convertLimitsDTO(dto.limits)
    }

}

export const convertTermsConditionsDTO = (dto: TermsConditionsDTO | undefined): TermsConditions | undefined => {
    if (dto == undefined) {
        return undefined;
    }
    return {
        canMute: dto.canMute,
        key: dto.key,
        site_url: dto.site_url,
        version: dto.version
    }
}

export const convertLimitsDTO = (dto: LimitsDTO): Limits => {
    return {
        hasUserFeedback: dto.has_user_feedback,
        numTokensPerConversation: dto.num_tokens_per_conversation,
        ratioTokensForSoftLimit: dto.ratio_tokens_for_soft_limit,
        hasPublicConversationsMachine: dto.has_public_conversations_machine,
        hasPublicConversationsUser: dto.has_public_conversations_user,
        numCharactersPerMessage: dto.num_characters_per_message,
        numAdHocDocuments: dto.num_ad_hoc_documents,
        sizeAdHocDocuments: dto.size_ad_hoc_documents,
        tokensAdHocDocuments: dto.tokens_ad_hoc_documents
    }

}

export const convertRating = (rating: Rating): RatingDTO => {
    return {
        feedback: rating.rating,
        feedback_comment: rating.comment
    }
}

export const convertConversationDTO = (dto: ConversationDTO): Conversation => {
    return {
        messages: dto.messages.map(convertConversationElementDTO),
        documents: dto.documents.map(convertDocumentDTO)
    }
}

export const convertConversationElementDTO = (dto: ConversationElementDTO): ConversationElement => {
    return {
        created_at: dto.created_at,
        id: dto.id,
        question: dto.question,
        answer: dto.answer,
        status: dto.status,
        llm: dto.llm,
        duration_ms: dto.duration_ms,
        total_tokens: dto.total_tokens,
        run_in_additional_information: dto.run_in_additional_information,
        feedback: { elementId: dto.id, rating: dto.feedback || 0, comment: dto.feedback_comment || '' },
        prompt: dto.prompt,
        documents: dto.documents,
        ongoing: dto.ongoing
    }
}

export const convertDocumentDTO = (dto: DocumentDTO): Document => {
    return {
        created_at: dto.created_at,
        modified_at: dto.modified_at,
        id: dto.id,
        title: dto.title,
        description: dto.description,
        url: dto.url,
        metadata: dto.metadata,
        status: dto.status,
        classification: dto.classification
    }
}
