<mat-sidenav-container class="tenant-admin-container">
    <mat-sidenav [opened]="conversationDrawerState$ | async" mode="side" class="tenant-admin-menu">
        <mat-nav-list>
<!--            <mat-list-item routerLink="overview"
                           routerLinkActive
                           #routerLinkActiveInstance1="routerLinkActive"
                           [activated]="routerLinkActiveInstance1.isActive"
            >
                <mat-icon matListItemIcon>monitoring</mat-icon><span matListItemTitle>Overview</span>
            </mat-list-item>-->
            <mat-list-item routerLink="promptlab"
                           routerLinkActive
                           #routerLinkActiveInstance2="routerLinkActive"
                           [activated]="routerLinkActiveInstance2.isActive"
            >
                <mat-icon matListItemIcon>theater_comedy</mat-icon><span matListItemTitle>{{'PROMPTLAB.TITLE' | translate}}</span>
            </mat-list-item>
            <mat-list-item routerLink="permissions"
                           routerLinkActive
                           #routerLinkActiveInstance3="routerLinkActive"
                           [activated]="routerLinkActiveInstance3.isActive"
                           *ngIf="((activeTenant$ | async)?.access || 0) > 2"
            >
                <mat-icon matListItemIcon>manage_accounts</mat-icon><span matListItemTitle>{{'PERMISSIONS.TITLE' | translate}}</span>
            </mat-list-item>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="tenant-admin-panel">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>

</mat-sidenav-container>

