import {AfterViewInit, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {activeTenant} from "../../redux/reducers/group.reducer";
import {Store} from "@ngrx/store";
import {State} from "../../redux/reducers";
import {Tenant} from "../../model/group.model";
import {PermissionActions} from "../../redux/actions/permission.actions";
import {permissionsPrompt} from "../../redux/reducers/permissions.reducer";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {Permission} from "../../model/permissions.model";
import {MatDialog} from "@angular/material/dialog";
import {Observable} from "rxjs";
import {ConfirmDialogComponent, ConfirmDialogData} from "../confirm-dialog/confirm-dialog.component";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-admin-permissions',
  standalone: false,
  templateUrl: './admin-permissions.component.html',
  styleUrl: './admin-permissions.component.scss'
})
export class AdminPermissionsComponent implements OnInit, AfterViewInit {

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

    activeTenant: Tenant | undefined;
  permissions$ = this.store.select(permissionsPrompt);
  tableDataSource = new MatTableDataSource<Permission>();
  displayedColumns: string[] = ['email', 'tier', 'actions'];
  protected tiers = [0,1,2,3];

  constructor(private store: Store<State>, protected dialog: MatDialog, protected translate: TranslateService) { }

    ngAfterViewInit() {
        this.tableDataSource.sort = this.sort;
        this.tableDataSource.paginator = this.paginator;
    }

  ngOnInit() {
    this.store.select(activeTenant).subscribe(
        tenant => {
          if (tenant) {
            this.activeTenant = tenant;
            this.store.dispatch(PermissionActions.loadPermissions());
          }
        }
    )
    this.permissions$.subscribe(
        permissions => {
          this.tableDataSource.data = permissions;
        }
    )
  }

    editRow(element: Permission): void {
        const index = this.tableDataSource.data.indexOf(element);
        if (index >= 0) {
            const updatedElement = { ...element, isEditMode: !element.isEditMode };
            const updatedData = [...this.tableDataSource.data];
            updatedData[index] = updatedElement;
            this.tableDataSource.data = updatedData;
        }
    }

    cancelEditRow(element: Permission): void {
        const index = this.tableDataSource.data.indexOf(element);
        if (element.isNew) {
            this.removeRow(element);

        }else {
          if (index >= 0) {
              const updatedElement = {...element, isEditMode: !element.isEditMode};
              const updatedData = [...this.tableDataSource.data];
              updatedData[index] = updatedElement;
              this.tableDataSource.data = updatedData;
          }
      }
        this.tableDataSource._updateChangeSubscription();
    }

    savePermission(element: Permission): void {
      console.log(element);
        if (element.email && element.tier > -1) {
            this.store.dispatch(PermissionActions.savePermission({permission: element}));
            const index = this.tableDataSource.data.indexOf(element);
            if (index >= 0) {
                const updatedElement = {...element, isEditMode: !element.isEditMode, isNew: false};
                const updatedData = [...this.tableDataSource.data];
                updatedData[index] = updatedElement;
                this.tableDataSource.data = updatedData;
            }
        }
    }
    deletePermission(element: Permission): void {
      if (!element.isNew){
      this.dialog.open(ConfirmDialogComponent, {
          width: '250px',
          data: {
              title: this.translate.instant('PERMISSIONS.DELETE_TITLE'),
              message: this.translate.instant('PERMISSIONS.DELETE_MESSAGE', {email: element.email})
          }
      }).afterClosed().subscribe(result => {
            if (result) {
                this.store.dispatch(PermissionActions.deletePermission({permission: element}));
                this.removeRow(element);
            }
      });
      }else {
          this.removeRow(element);
      }


    }

    removeRow(element: Permission): void {
            const updatedData = [...this.tableDataSource.data.filter(e => e !== element)];
            this.tableDataSource.data = updatedData;
            this.tableDataSource._updateChangeSubscription();
    }

  addPermission() {
      this.tableDataSource.data = [...this.tableDataSource.data, {email: '', tier: 0, isEditMode: true, isNew: true}];
      this.tableDataSource._updateChangeSubscription();
      this.paginator.length = this.tableDataSource.data.length;
      this.paginator.lastPage();
  }

    protected readonly confirm = confirm;
}
