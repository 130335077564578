import {createReducer, on} from '@ngrx/store';
import {GroupActions} from '../actions/group.actions';
import {Tenant} from "../../model/group.model";
import {State} from "./index";
import {Prompt} from "../../model/prompt.model";

export interface GroupState {
    groups: Array<Tenant>;
    loaded: boolean;
    loading: boolean;
    hasActiveGroup: boolean;
    activeGroup?: Tenant;
    activeGroupId?: string;
    noGroupPermissions: boolean;
    activeTopics: Array<string>;
    prompts: Prompt[];
    activePrompt?: Prompt;
}

export const initialState: GroupState = {
    groups: [],
    loaded: false,
    loading: false,
    hasActiveGroup: false,
    noGroupPermissions: false,
    activeTopics: [],
    prompts: []

};

export const reducer = createReducer(
  initialState,
    on(GroupActions.groupsLoad, state => ({...state, loading: true})),
    on(GroupActions.groupsLoadSuccess, (state, {data}) => ({...state, groups: data, loaded: true, loading: false})),
    on(GroupActions.groupsLoadFailure, (state, {error}) => ({...state, loading: false})),
    on(GroupActions.groupsActivate, (state, activation) => (
        {...state, activeGroupId: activation.tenant.id, activeGroup: state.groups.filter(group => group.id === activation.tenant.id)[0], hasActiveGroup: true})
    ),
    on(GroupActions.groupsEmpty, (state) => ({...state, noGroupPermissions: true})),
    on(GroupActions.groupsActivateTopic, (state, {topicId}) => ({...state, activeTopics: [...state.activeTopics.filter(t => t !== topicId), topicId]})),
    on(GroupActions.groupsDeactivateTopic, (state, {topicId}) => ({...state, activeTopics: [...state.activeTopics.filter(t => t !== topicId)]})),
    on(GroupActions.groupsLoadPromptsSuccess, (state, {prompts}) => ({...state,  prompts: prompts})),
    on(GroupActions.groupsActivatePrompt, (state, {prompt}) => ({...state, activePrompt: prompt, activeTopics: prompt.defaultEnabledTopics}))
);

export const selectGroups = (state: GroupState) => state.groups;
export const selectActiveGroupId = (state: State) => state.groups.activeGroupId;
export const activeTenant = (state: State) => state.groups.activeGroup;
export const activeTopics = (state: State) => state.groups.activeTopics;
export const userPrompts = (state: State) => state.groups.prompts;
export const activePrompt = (state: State) => state.groups.activePrompt;

