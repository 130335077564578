import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {Permission} from "../../model/permissions.model";

export const PermissionActions = createActionGroup({
    source: 'Permissions',
    events: {
        'Load Permissions': emptyProps(),
        'Load Permissions Success': props<{ data: Permission[] }>(),
        'Load Permissions Failure': props<{ error: any }>(),
        'Save Permission': props<{ permission: Permission }>(),
        'Save Permission Success': emptyProps(),
        'Save Permission Failure': props<{ error: any }>(),
        'Delete Permission': props<{ permission: Permission }>(),
        'Delete Permission Success': props<{ label: string }>(),
        'Delete Permission Failure': props<{ error: any }>(),
        'Add Permission': emptyProps()
    }
});