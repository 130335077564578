<div class="chat-question-field">
    <mat-form-field subscriptSizing="dynamic" class="chat-input" appearance="outline">
                <textarea
                        autofocus
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="20"
                        class="chat-input-textarea"
                        placeholder="{{'CONV.ASK_PLACEHOLDER' | translate}}"
                        matInput
                        maxlength="5000"
                        #inputMessage
                        [disabled]="disabled"
                        [(ngModel)]="message"
                        (keyup.enter)="addUserPrompt(message)"
                ></textarea>
        <span *ngIf="message.length > showLengthIndicatorAfter" class="remaining">{{message.length}}/{{ maxMessageLength }}</span>
        <mat-toolbar class="action-buttons">
            <mat-toolbar-row>
                <button mat-icon-button
                        (click)="addFile()"
                        [matBadge]="conversation?.documents?.length"
                        [matBadgeHidden]="conversation?.documents?.length == 0"
                        matBadgeOverlap="true"
                        [disabled]="disabled"
                        [matTooltip]="('FILE.INFOTEXT' | translate : { value: 2 - (conversation?.documents?.length || 0), total: 2 })"

                >
                    <mat-icon>attach_file_add</mat-icon>
                </button>
                <button mat-icon-button *ngIf="(activePrompt?.availableTopics?.length || 0) > 0" [matMenuTriggerFor]="topicMenu" [disabled]="disabled" [matBadge]="activeTopics.length" [matBadgeHidden]="activeTopics.length == 0">
                    <mat-icon [matBadge]="">document_search</mat-icon>
                </button>
                <mat-menu #topicMenu="matMenu" [overlapTrigger]="false">
                    <mat-checkbox *ngFor="let topic of activePrompt?.availableTopics" (click)="$event.stopPropagation();" (change)="toggleTopic($event, topic)" [checked]="activeTopics.includes(topic)">
                        {{ extractTopicName(tenant?.topics || [], topic) }}
                    </mat-checkbox>
                </mat-menu>
                <app-prompt-selection [prompts]="of(availablePrompts)" [selected]="of(activePrompt)"></app-prompt-selection>
                <div class="chat-disclaimer-container"
                     matTooltip="{{ 'CONV.DISCLAIMER' | translate }}"
                     matTooltipShowDelay="2000"
                     matTooltipHideDelay="500"
                >{{ 'CONV.DISCLAIMER' | translate }}</div>
                <button
                        mat-icon-button
                        color="primary"
                        [disabled]="disabled"
                        (click)="addUserPrompt(message);">
                    <mat-icon>send</mat-icon>
                </button>
            </mat-toolbar-row>
        </mat-toolbar>
    </mat-form-field>
</div>