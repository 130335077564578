import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostListener,
    OnInit,
    ViewChild, 
    AfterViewInit,
    OnDestroy
} from '@angular/core';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {ChatConversation} from "../../model/chat-conversation";
import {MatDrawerMode} from '@angular/material/sidenav';
import {State} from "../../redux/reducers";
import {Store} from "@ngrx/store";
import {ConversationsActions} from "../../redux/actions/conversations.actions";
import {
    MatDialog
} from '@angular/material/dialog';
import {FeedbackComponent} from "../feedback/feedback.component";
import {FileUploadComponent} from "../file-upload/file-upload.component";
import {AppActions} from "../../redux/actions/app.actions";
import {EditTitleComponent} from "../edit-title/edit-title.component";
import {MatMenu} from "@angular/material/menu";
import {MatChipSelectionChange} from "@angular/material/chips";
import {activePrompt, activeTenant, activeTopics, userPrompts} from "../../redux/reducers/group.reducer";
import {GroupActions} from "../../redux/actions/group.actions";
import {ChatMessage, Rating} from "../../model/chat-message";
import {Topic} from "../../model/group.model";
import {ActivatedRoute, Router} from "@angular/router";
import {ConversationElement} from "../../model/conversation-element.model";
import {concatMap, map, mergeMap, Observable, of, take, zip} from "rxjs";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {LoggingService} from "../../service/logging.service";
import {environment} from "../../../environments/environment";

export interface EditTitleDialogData {
    conversationId: string;
    title: string;
}

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrl: './chat.component.scss',
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: false
})
export class ChatComponent implements OnInit, AfterViewInit, OnDestroy {
    protected intro_class:string[] = [];
    protected conversation: ChatConversation = new ChatConversation();
    response: ChatMessage;

    protected conversations: string[] = ["Test conversation 1", "Test conversation with a long title", "Test conversation with an even longer title"];
    @ViewChild('autosize') autosize?: CdkTextareaAutosize;
    @ViewChild('conversationMenu') conversationMenu?: MatMenu;
    conversations$ = this.store.select((state: State) => state.conversations.conversations)
    streamingAnswer$ = this.store.select((state: State) => state.conversations.activeConversationDetail.messages.filter((message) => message.ongoing)[0])
    latestConversation$ = this.store.select((state: State) => state.conversations.latestConversationId)
    conversationDetail$ = this.store.select((state: State) => state.conversations.activeConversationDetail)
    activeConversationId$ = this.store.select((state: State) => state.conversations.activeConversationId)
    conversationDrawerState$ = this.store.select((state: State) => state.app.conversationPanelOpen)
    activeTenant$ = this.store.select(activeTenant)
    transientResponse$ = this.store.select((state: State) => state.conversations.transientResponse)
    appBusyState$ = this.store.select((state: State) => state.app.busy)
    hasMoreConversations$ = this.store.select((state: State) => state.conversations.hasMoreConversations)
    lastCursor$ = this.store.select((state: State) => state.conversations.lastCursor)
    isLoadingMore$ = this.store.select((state: State) => state.conversations.isLoadingMore)

    
    // Observable for checking if there are public conversations
    hasPublicConversations$ = this.conversations$.pipe(
        map(conversations => {
            if (!conversations || conversations.length === 0) return false;
            return conversations.some(c => c.visibility === 'public');
        })
    )

    prompts$ = this.store.select(userPrompts)
    activePrompt$ = this.store.select(activePrompt)
    activeTopics$ = this.store.select(activeTopics)
    @ViewChild('chatScrollContainer') private chatContainer!: ElementRef;
    @ViewChild('conversationListContainer') private conversationListContainer!: ElementRef;
    @ViewChild('scrollSentinel') private scrollSentinel!: ElementRef;
    @ViewChild('inputMessage') private inputMessage?: ElementRef<HTMLTextAreaElement> | undefined;
    drawerMode: MatDrawerMode = 'side';
    desktopViewWidth: number = 800;
    visibilityFilter = "all";
    
    // Method to track visibility filter changes
    onVisibilityFilterChange(newValue: string) {
        this.visibilityFilter = newValue;
    }

    prompts = [{id: 1, color: 'orange', title: 'KSC Kategorisierung'}, {id: 2, color: 'green', title: 'KSC Mailbeantwortung'}]
    prompt = this.prompts[1];

    @HostListener('window:resize', ['$event.target.innerWidth'])
    onResize(width: number) {
        if (width >= this.desktopViewWidth) {
            this.drawerMode = 'side';
            this.store.dispatch(AppActions.appUpdateViewMode({viewMode: "desktop"}))
        } else {
            this.drawerMode = 'over';
            this.store.dispatch(AppActions.appUpdateViewMode({viewMode: "mobile"}))

        }

    }




    constructor(
        private activeRoute: ActivatedRoute, 
        private router: Router, 
        protected store: Store<State>, 
        public dialog: MatDialog,
        private loggingService: LoggingService
    ) {
        this.response = {ongoing: false};
    }
    ngOnInit() {
        this.onResize(window.innerWidth);
        this.activeRoute.params.subscribe(params => {
            this.activeTenant$.subscribe((activeTenant) => {
                if(activeTenant) {
                    this.latestConversation$.subscribe((conversation) => {
                        if(conversation != undefined) {
                            if (params['conversationId']) {
                                this.store.dispatch(ConversationsActions.conversationActivate({conversationId: params['conversationId']}))
                            } else {
                                if (conversation.requireNew) {
                                    this.store.dispatch(ConversationsActions.conversationNew({groupId: activeTenant.id}))
                                } else{
                                    this.router.navigate(['conversation', conversation.id], {relativeTo: this.activeRoute.parent})

                                }

                            }
                        }
                    });
                }
            });
        });

    }

    private intersectionObserver?: IntersectionObserver;

    ngAfterViewInit() {
        this.conversationDetail$.subscribe((conv) => {
            if (conv.messages.length == 0) {
                this.intro_class = ["chat-llm-answer-text"]
            } else {
                this.intro_class = ["chat-llm-answer-text"]
            }
            // Focus on input when there are no open dialogs
            if (!this.dialog.openDialogs || !this.dialog.openDialogs.length) {
                this.inputMessage?.nativeElement.focus();
            }
            this.activeConversationId$.subscribe(() => {
                setTimeout(() => {
                    this.scrollToBottom();
                }, 500);
            });
        });
        
        // Set up intersection observer for infinite scrolling
        setTimeout(() => {
            this.setupIntersectionObserver();
        }, 1000);
    }
    
    private setupIntersectionObserver() {
        // Ensure the scrollSentinel element is available
        if (!this.scrollSentinel) {
            console.warn('Scroll sentinel element not found');
            return;
        }
        
        // Set up intersection observer for infinite scrolling
        
        // Create a new IntersectionObserver to watch the scroll sentinel
        this.intersectionObserver = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    console.log('Sentinel is visible, loading more conversations');
                    // Scroll sentinel is visible, load more conversations
                    this.loadMoreConversationsAction();
                }
            });
        }, {
            root: this.conversationListContainer?.nativeElement,
            rootMargin: '0px 0px 0px 0px', // Load when sentinel is at viewport
            threshold: 0.1 // Trigger when 10% of the sentinel is visible
        });
        
        // Start observing the sentinel
        this.intersectionObserver.observe(this.scrollSentinel.nativeElement);
    }

    saveFeedback(feedback: Rating) {
        this.activeConversationId$.pipe(take(1)).subscribe((conversationId) => {
            if (conversationId != undefined) {
                this.store.dispatch(ConversationsActions.rateAnswer({conversationId: conversationId, rating: feedback}));
                
                // Send event to Application Insights
                this.activeTenant$.pipe(take(1)).subscribe((tenant) => {
                    this.loggingService.logEvent('FEEDBACK_GIVEN', {
                        tenantId: tenant?.id,
                        conversationId: conversationId,
                        rating: feedback.rating
                    });
                });
            }
        });
    }


    onQuestionSubmit(question: string){
        this.transientResponse$.pipe(take(10)).subscribe((response) => {
            this.scrollToBottom();
        });
    }


    scrollToBottom = () => {
        try {
            this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
        } catch (err) {}
    }




    deleteConversation(e: Event, conversationId: string){
        e.preventDefault();
        e.stopImmediatePropagation();
        this.store.dispatch(ConversationsActions.deactivateActiveConversation())
        this.store.dispatch(ConversationsActions.conversationDelete({conversationId: conversationId}))

    }

    editTitle(e: Event, conversationId: string, current: string){
        e.preventDefault();
        const dialogRef = this.dialog.open(EditTitleComponent, {
            data: {conversationId: conversationId, title: current}
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result !== undefined) {
                this.store.dispatch(ConversationsActions.conversationRename({conversationId: conversationId, newTitle: result}))
            }
        });

    }




    openFeedbackPanel() {
        let dialogRef = this.dialog.open(FeedbackComponent, {
            panelClass: 'feedback-panel',
            minWidth: '640px',
            minHeight: '700px'
        });
    }






    protected readonly length = length;

    elementKey(element: ConversationElement){
        return element.id+element.answer.length;
    }

    // Manual scroll event handler (backup method)
    loadMoreConversations(event: Event) {
        console.log('Loading more conversations 1');
        // If the conversationListContainer isn't available yet, return
        if (!this.conversationListContainer) return;
        
        const container = this.conversationListContainer.nativeElement;
        const scrollPosition = container.scrollTop;
        const scrollHeight = container.scrollHeight;
        const clientHeight = container.clientHeight;
        const distanceToBottom = scrollHeight - scrollPosition - clientHeight;
        
        // Check if we've scrolled near the bottom
        
        // Check if scrolled to near the bottom of conversation list
        if (distanceToBottom <= 100) {
            this.loadMoreConversationsAction();
            console.log('Loading more conversations 2');
        }
    }
    
    // Action to load more conversations - separated to be called from both scroll and intersection observer
    loadMoreConversationsAction() {
        // Create a stream combining all the values we need for loading more conversations
        zip(
            this.hasMoreConversations$.pipe(take(1)),
            this.isLoadingMore$.pipe(take(1)),
            this.lastCursor$.pipe(take(1)),
            this.activeTenant$.pipe(take(1)),
            (hasMore, isLoading, cursor, tenant) => ({ hasMore, isLoading, cursor, tenant })
        ).subscribe(({ hasMore, isLoading, cursor, tenant }) => {
            // Only proceed if we have more conversations, we're not already loading, 
            // we have a cursor, and we have an active tenant
            if (hasMore && !isLoading && cursor && tenant) {
                // Dispatch the action to load more conversations
                this.store.dispatch(ConversationsActions.conversationsLoadMore({
                    groupId: tenant.id,
                    limit: this.environment.app.conversations.additionalCount,
                    cursor
                }));
            }
        });
    }
    
    ngOnDestroy() {
        // Clean up the intersection observer when component is destroyed
        if (this.intersectionObserver) {
            // Clean up intersection observer
            this.intersectionObserver.disconnect();
            this.intersectionObserver = undefined;
        }
    }
    
    protected readonly userPrompts = userPrompts;
    protected readonly GroupActions = GroupActions;
    protected readonly activeTopics = activeTopics;
    protected readonly of = of;
    protected readonly environment = environment;
}
