import { createReducer, on } from '@ngrx/store';
import { PromptLabActions } from "../actions/prompt-lab.actions";
import { State } from "./index";
import { PromptDTO } from "../../model/dto.model";
import { Prompt, PromptAppearance } from "../../model/prompt.model";
import { environment } from "../../../environments/environment";

export const promptLabFeatureKey = 'promptLab';

export const tenantLLMs = (state: State) => state.promptlab.llms;
export const tenantTopics = (state: State) => state.promptlab.tenantTopics;
export const tenantPromptLabels = (state: State) => state.promptlab.prompts;
export const tenantPrompts = (state: State) => state.promptlab.prompts;

export interface PromptlabState {
    llms: string[];
    tenantTopics: string[];
    prompts: Prompt[];
    accessibleBy: string[];
    usedBy: string[];
}

export const initialState: PromptlabState = {
    llms: [],
    tenantTopics: [],
    prompts: [],
    accessibleBy: [],
    usedBy: []
};

export const reducer = createReducer(
    initialState,
    on(PromptLabActions.loadPromptLabSuccess, (state: PromptlabState, data) => ({ ...state, promts: data.data.prompts, llms: data.data.llms, tenantTopics: data.data.tenant_topics })),
    on(PromptLabActions.loadPromptsSuccess, (state: PromptlabState, data) => ({ ...state, prompts: data.data.map(p => mapToPromptObject(p)) })),
    on(PromptLabActions.createNewPrompt, (state: PromptlabState) => ({ ...state, prompts: [{ unsaved: true, unsavedChanges: true, appearance: environment.app.defaultPromptAppearance, accessibleBy: 'users', usedBy: 'users', availableTopics: [], defaultEnabledTopics: [], createdAt: new Date(), modifiedAt: new Date(), id: '', title: 'New', label: '', llm: '', goalDescription: '', grounding: false, default: false, schema_out: '{}' }, ...state.prompts] })),
    on(PromptLabActions.deletePromptSuccess, (state: PromptlabState, data) => ({ ...state, prompts: state.prompts.filter(p => p.label !== data.label) })),
);

export const mapToPromptObject = function(backendPrompt: PromptDTO): Prompt {
    let appearance = environment.app.defaultPromptAppearance as PromptAppearance;
    try {
        appearance = backendPrompt.characteristic as unknown as PromptAppearance;
    } catch (e) {
        console.error(e);
    }
    
    // Handle schema_out to ensure it's a string
    // If the backend returns null or undefined, default to '{}' which will disable the toggle
    let schemaOutValue = backendPrompt.schema_out || '{}';
    if (typeof schemaOutValue === 'object') {
        schemaOutValue = JSON.stringify(schemaOutValue, null, 2);
    }
    
    return {
        createdAt: new Date(Date.parse(backendPrompt.created_at)),
        modifiedAt: new Date(Date.parse(backendPrompt.modified_at)),
        id: backendPrompt.id,
        title: backendPrompt.title,
        label: backendPrompt.label,
        accessibleBy: backendPrompt.accessible_by,
        usedBy: backendPrompt.used_by,
        //some endpoints return available_topics as a string, some as an array
        availableTopics: Array.isArray(backendPrompt.available_topics) ? backendPrompt.available_topics : backendPrompt.available_topics.split(',').filter(t => t !== ""),
        //some endpoints return default_topics as a string, some as an array
        defaultEnabledTopics: Array.isArray(backendPrompt.default_topics) ? backendPrompt.default_topics : backendPrompt.default_topics.split(',').filter(t => t !== ""),
        llm: backendPrompt.llm,
        goalDescription: backendPrompt.prompt_goal,
        grounding: backendPrompt.grounding_toggle,
        default: backendPrompt.default,
        schema_out: schemaOutValue,
        appearance: appearance,
        unsavedChanges: false,
        unsaved: false
    }
}
export const mapToPromptDTO = function(frontendPrompt: Prompt): PromptDTO {
    return {
        created_at: frontendPrompt.createdAt?.toISOString(),
        modified_at: frontendPrompt.modifiedAt?.toISOString(),
        id: frontendPrompt.id,
        title: frontendPrompt.title,
        label: frontendPrompt.label,
        accessible_by: frontendPrompt.accessibleBy,
        used_by: frontendPrompt.usedBy,
        available_topics: frontendPrompt.availableTopics.join(','),
        default_topics: frontendPrompt.defaultEnabledTopics.join(','),
        llm: frontendPrompt.llm,
        prompt_goal: frontendPrompt.goalDescription,
        grounding_toggle: frontendPrompt.grounding,
        default: frontendPrompt.default,
        schema_out: frontendPrompt.schema_out,
        characteristic: JSON.stringify(frontendPrompt.appearance)
    }
}








