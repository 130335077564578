<div class="admin-header"><div>{{ 'PERMISSIONS.TITLE' | translate }}</div><div><button mat-flat-button (click)="addPermission()">
    <mat-icon>add_circle</mat-icon>
    {{ 'PERMISSIONS.ADD_PERMISSION' | translate }}
</button></div></div>
<div class="admin-permissions-panel">
    <table mat-table matSort [dataSource]="tableDataSource">
        <!-- Email Column -->
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Email"  class="table-header">
                {{ 'PERMISSIONS.EMAIL' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="!element.isEditMode; else editEmail">
                    {{ element.email }}
                </ng-container>
                <ng-template #editEmail>
                    <mat-form-field appearance="outline" subscriptSizing="dynamic">
                    <input matInput [(ngModel)]="element.email" [email]="true" required>
                    </mat-form-field>
                </ng-template>
            </td>
        </ng-container>

        <!-- Tier Column -->
        <ng-container matColumnDef="tier">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by user tier" class="table-header">
                {{ 'PERMISSIONS.TIER.NAME' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="!element.isEditMode; else editTier">
                    {{ 'PERMISSIONS.TIER.' + element.tier | translate }}
                </ng-container>
                <ng-template #editTier>
                    <mat-form-field appearance="outline" subscriptSizing="dynamic">
                    <mat-select [(ngModel)]="element.tier">
                        <mat-option *ngFor="let tier of tiers" [value]="tier">
                            {{ 'PERMISSIONS.TIER.' + tier | translate }}
                        </mat-option>
                    </mat-select>
                    </mat-form-field>
                </ng-template>
            </td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef  class="table-header">{{ 'PERMISSIONS.ACTIONS' | translate }}</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button (click)="editRow(element)" *ngIf="!element.isEditMode">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button (click)="savePermission(element)" *ngIf="element.isEditMode">
                    <mat-icon>check_circle</mat-icon>
                </button>
                <button mat-icon-button (click)="deletePermission(element)" *ngIf="!element.isEditMode">
                    <mat-icon>delete</mat-icon>
                </button>
                <ng-template #delete>
                    <h1>This is a message</h1>
                </ng-template>
                <button mat-icon-button (click)="cancelEditRow(element)" *ngIf="element.isEditMode">
                    <mat-icon>cancel</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                   showFirstLastButtons
                   aria-label="Select page of periodic elements"
                   getRangeLabel="Berechtigungen pro Seite">
    </mat-paginator>
</div>