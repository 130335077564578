<div class="compliance-header">
    <mat-icon>assured_workload</mat-icon>
    <div class="compliance-title">{{ 'COMPLIANCE.TITLE' | translate }}</div>
    </div>
<div class="compliance-panel">
    <div class="center" [innerHtml]=" 'COMPLIANCE.TEXT' | translate: { url: 'https://bkwfmbenergie.sharepoint.com/sites/PowerGrid/SiteAssets/SitePages/K%C3%BCnstliche-Intelligenz/Anleitung_Edison_V', version: data.tc?.version, language: translate.currentLang } | safeHtml ">

    </div>
</div>
<mat-dialog-actions>
    <button mat-raised-button  [mat-dialog-close]="true">{{ "GENERIC.OK" | translate }}</button>
</mat-dialog-actions>