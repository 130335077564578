import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {RestService} from "../../service/rest.service";
import {Store} from "@ngrx/store";
import {State} from "../reducers";
import {catchError, map, of, switchMap, withLatestFrom} from "rxjs";
import {activeTenant} from "../reducers/group.reducer";
import {PermissionDTO} from "../../model/dto.model";
import {PermissionActions} from "../actions/permission.actions";
import {AppActions} from "../actions/app.actions";
import {mapToPermissionDTO} from "../reducers/permissions.reducer";

@Injectable()
export class PermissionEffects {


    constructor(private actions$: Actions, private restService: RestService, private store: Store<State>) {

    }

    loadPermissions$ = createEffect(() => this.actions$.pipe(
        ofType(PermissionActions.loadPermissions),
        withLatestFrom(this.store.select(activeTenant)),
        switchMap((tenant) => this.restService.get<PermissionDTO[]>(`/v0/platform/tenants/${tenant[1]!.id}/user-tier`).pipe(
            map(permissions => {
                return PermissionActions.loadPermissionsSuccess({data: permissions})
            }),
            catchError(error => of(AppActions.appGenericError({error})))
        ))
    ));

    deletePermission$ = createEffect(() => this.actions$.pipe(
        ofType(PermissionActions.deletePermission),
        withLatestFrom(this.store.select(activeTenant)),
        switchMap(([action, tenant]) => this.restService.delete(`/v0/platform/tenants/${tenant!.id}/user-tier`).pipe(
            map(() => PermissionActions.deletePermissionSuccess({label: action.permission.email})),
            catchError(error => of(AppActions.appGenericError({error})))
        ))
    ));

    savePermission$ = createEffect(() => this.actions$.pipe(
        ofType(PermissionActions.savePermission),
        withLatestFrom(this.store.select(activeTenant)),
        switchMap(([action, tenant]) => this.restService.post<PermissionDTO>(`/v0/platform/tenants/${tenant!.id}/user-tier`, mapToPermissionDTO(action.permission)).pipe(
            map(() => PermissionActions.savePermissionSuccess()),
            catchError(error => of(AppActions.appGenericError({error})))
        ))
    ));
}