import { createReducer, on } from '@ngrx/store';
import { ConversationsActions } from '../actions/conversations.actions';
import {Conversation, ConversationStub} from "../../model/conversation.model";
import {State} from "./index";
import {ChatMessage} from "../../model/chat-message";
import * as uuid from 'uuid';
import {GroupActions} from "../actions/group.actions";

export const EMPTY_CONVERSATION = {messages: [], documents: []} as Conversation;
export interface ConversationsState {
    conversations: Array<ConversationStub>;
    activeConversationId?: string;
    activeConversation: boolean;
    activeConversationDetail: Conversation;
    latestConversationId?: {id: string | undefined, requireNew: boolean};
    transientResponse: ChatMessage;
    hasMoreConversations: boolean;
    lastCursor?: string;
    isLoadingMore: boolean;
}

export const initialState: ConversationsState = {
    conversations: [],
    activeConversation: false,
    activeConversationDetail: EMPTY_CONVERSATION,
    transientResponse: {ongoing: false, chunk_message: ""},
    hasMoreConversations: false,
    isLoadingMore: false
};

export const reducer = createReducer(
  initialState,
    on(ConversationsActions.conversationsLoadSuccess, (state, {data, latestConversationId, requireNewConversation, hasMore, cursor}) => {
        // Check if this is from a 'loadMore' action or an initial load
        const isLoadMore = state.isLoadingMore;
        
        // If it's a load more action, append the new conversations to existing ones
        // Otherwise, replace the entire list (initial load)
        const updatedConversations = isLoadMore ? 
            [...state.conversations, ...data.filter(newConv => 
                !state.conversations.some(existingConv => existingConv.id === newConv.id))] : 
            data;
            
        return {
            ...state, 
            conversations: updatedConversations,
            latestConversationId: isLoadMore ? 
                state.latestConversationId : // Keep existing latest when loading more
                {id: latestConversationId, requireNew: requireNewConversation},
            hasMoreConversations: hasMore,
            lastCursor: cursor,
            isLoadingMore: false
        };
    }),
    on(ConversationsActions.conversationsLoadMore, (state) => ({...state, isLoadingMore: true})),
    on(ConversationsActions.conversationActivate, (state, {conversationId}) => ({...state, activeConversation: true, activeConversationId: conversationId})),
    on(ConversationsActions.conversationRename, (state, {conversationId, newTitle}) => ( {...state, conversations: state.conversations.map((conv) => conv.id === conversationId ? {...conv, title: newTitle} : conv)})),
    on(ConversationsActions.conversationRenameSuccess, (state, {conversationId, newTitle}) => ( {...state, conversations: state.conversations.map((conv) => conv.id === conversationId ? {...conv, title: newTitle} : conv)})),
    on(ConversationsActions.conversationDelete, (state, {conversationId}) => ({...state, conversations: state.conversations.filter((conv) => conv.id !== conversationId)})),
    on(ConversationsActions.conversationNewSuccess, (state, {conversation}) => ({...state, conversations: [...state.conversations, conversation], latestConversationId: {id: conversation.id, requireNew: false}})),
    on(ConversationsActions.conversationDetailLoadSuccess, (state, {data}) => ({...state, activeConversationDetail: data})),
    on(ConversationsActions.conversationDetailAddResponseChunk, (state, {response_chunk}) => (
        {...state,  transientResponse: {ongoing: true, chunk_message: response_chunk.chunk_message}})),

    on(ConversationsActions.conversationDetailAddFinalResponse, (state, {response}) => (
        {...state,  transientResponse: {ongoing: false, chunk_message: ""}, activeConversationDetail: {messages:
                        state.activeConversationDetail.messages.map(
                            (conv) => conv.ongoing ? {...conv, id: response.id || conv.id, ongoing: false,  answer: response.final_message || "NO_ANSWER", documents: response.documents || [], prompt: response.prompt, total_tokens: response.total_tokens, duration_ms: response.duration_ms} : conv
                        )
                    ,
                documents: state.activeConversationDetail.documents
            }
        })),
    on(ConversationsActions.conversationDetailAddQuestion, (state, {question}) => ({...state,
        modified_at: new Date().toString(),
        activeConversationDetail: {documents: state.activeConversationDetail.documents,
            messages: [...state.activeConversationDetail.messages, {
            created_at: new Date().toString(),
            id: uuid.v4(),
            question: question,
            answer: "",
            llm: "",
            feedback: undefined,
                documents: [],
                run_in_additional_information: "",
                status: "valid",
            ongoing: true}
        ]},
           transientResponse: {ongoing: true, chunk_message: "\n\n\n\n\n\n"}
    }
    )),
    on(ConversationsActions.conversationDeleteFile, (state, {conversationId, fileId}) => ({...state, activeConversationDetail: {messages: state.activeConversationDetail.messages, documents: state.activeConversationDetail.documents.filter((doc) => doc.id !== fileId)}})),
    on(ConversationsActions.deactivateActiveConversation, (state) => ({...state, activeConversation: false, activeConversationId: undefined, activeConversationDetail: EMPTY_CONVERSATION})),
    on(GroupActions.groupsActivate, ((state) => ({...initialState}))),
    on(ConversationsActions.rateAnswerSuccess, (state, {rating}) => ({
        ...state, activeConversationDetail: {
            ...state.activeConversationDetail,
            messages: state.activeConversationDetail.messages.map((message) => message.id === rating.elementId ? {...message, feedback: rating} : message)
            }}))
);


export const selectActiveConversationId = (state: State) => state.conversations.activeConversationId;
export const selectAvailablePrompts = (state: State) => state.groups.prompts;
export const selectDefaultPrompt = (state: State) => state.groups.prompts.filter((prompt) => prompt.default)[0];
export const selectPromptByLabel = (label: string) => (state: State) => state.groups.prompts.filter((prompt) => prompt.label === label)[0];