import {createActionGroup, emptyProps, props} from '@ngrx/store';
import {Tenant} from "../../model/group.model";
import {BackendError} from "../../model/error-message.model";
import {Prompt} from "../../model/prompt.model";

export const GroupActions = createActionGroup({
  source: 'Groups',
  events: {
    'Groups Load': emptyProps(),
    'Groups Load Success': props<{ data: Tenant[] }>(),
    'Groups Load Failure': props<{ error: BackendError }>(),
    'Groups Activate': props<{ tenant: Tenant}>(),
    'Groups Empty': emptyProps(),
    'Groups Activate Topic': props<{ topicId: string }>(),
    'Groups Deactivate Topic': props<{ topicId: string }>(),
    'Groups Load Prompts': emptyProps(),
    'Groups Load Prompts Success': props<{ prompts: Prompt[] }>(),
    'Groups Load Prompts Failure': props<{ error: BackendError }>(),
    'Groups Activate Prompt': props<{ prompt: Prompt }>(),
  }
});
