<div class="confirm-header">
    <mat-icon>info</mat-icon>
    <span class="confirm-dialog-title">{{ data.title}}</span><button mat-icon-button class="close-button" [mat-dialog-close]="true"><mat-icon>close</mat-icon></button></div>
<div class="confirm-panel">
        {{data.message}}
</div>
<mat-dialog-actions>
    <button mat-raised-button class="confirm-dialog-cancel-button" (click)="cancel()">{{ "GENERIC.CANCEL" | translate }}</button>
    <button mat-raised-button class="confirm-dialog-button" (click)="ok()">{{ "GENERIC.OK" | translate }}</button>
</mat-dialog-actions>

