import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

const ITEMS_PER_PAGE = 'PERMISSIONS.PAGINATOR.ITEMS_PER_PAGE';
const NEXT_PAGE = 'PERMISSIONS.PAGINATOR.NEXT_PAGE';
const PREV_PAGE = 'PERMISSIONS.PAGINATOR.PREV_PAGE';
const FIRST_PAGE = 'PERMISSIONS.PAGINATOR.FIRST_PAGE';
const LAST_PAGE = 'PERMISSIONS.PAGINATOR.LAST_PAGE';
const OF = 'PERMISSIONS.PAGINATOR.OF';

@Injectable()
export class MatPaginatorIntlService extends MatPaginatorIntl {
    private of: string = 'of';
    public constructor(private translate: TranslateService) {
        super();

        this.translate.onLangChange
            //.pipe(takeUntil(...))
            .subscribe((e: Event) => {
                this.getAndInitTranslations();
            });

        this.getAndInitTranslations();
    }

    public override getRangeLabel = (page: number, pageSize: number, length: number): string => {
        if (length === 0 || pageSize === 0) {
            return `0 ${this.of} ${length}`;
        }

        length = Math.max(length, 0);

        const startIndex: number = page * pageSize;
        const endIndex: number = startIndex < length
            ? Math.min(startIndex + pageSize, length)
            : startIndex + pageSize;

        return `${startIndex + 1} - ${endIndex} ${this.of} ${length}`;
    };

    public getAndInitTranslations(): void {
        this.translate.get([
            ITEMS_PER_PAGE,
            NEXT_PAGE,
            PREV_PAGE,
            FIRST_PAGE,
            LAST_PAGE,
            OF
        ])
            .subscribe((translation: any) => {
                this.itemsPerPageLabel = translation[ITEMS_PER_PAGE];
                this.nextPageLabel = translation[NEXT_PAGE];
                this.previousPageLabel = translation[PREV_PAGE];
                this.firstPageLabel = translation[FIRST_PAGE];
                this.lastPageLabel = translation[LAST_PAGE];
                this.of = translation[OF];
                this.changes.next();
            });
    }
}