{
  "name": "ai-platform-frontend",
  "version": "1.12.1",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --host 0.0.0.0",
    "prebuild": "node ./pipelines/process-changelog.mjs",
    "postbuild": "esbuild-visualizer --metadata build/stats.json",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "prebuild:production": "npm run prebuild",
    "build:production": "ng build --aot --optimization --progress --configuration=production",
    "postbuild:production": "npm run postbuild",
    "prebuild:staging": "npm run prebuild",
    "build:staging": "ng build --aot --optimization --progress --configuration=staging",
    "postbuild:staging": "npm run postbuild"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.1.3",
    "@angular/cdk": "^19.1.1",
    "@angular/common": "^19.1.3",
    "@angular/compiler": "^19.1.3",
    "@angular/core": "^19.1.3",
    "@angular/forms": "^19.1.3",
    "@angular/material": "^19.1.1",
    "@angular/platform-browser": "^19.1.3",
    "@angular/platform-browser-dynamic": "^19.1.3",
    "@angular/router": "^19.1.3",
    "@azure/msal-angular": "^3.0.19",
    "@azure/msal-browser": "^3.16.0",
    "@iplab/ngx-file-upload": "^19.0.3",
    "@microsoft/applicationinsights-web": "^3.3.0",
    "@ngneat/helipopper": "^9.2.1",
    "@ngrx/effects": "^19.0.0",
    "@ngrx/router-store": "^19.0.0",
    "@ngrx/store": "^19.0.0",
    "@ngrx/store-devtools": "^19.0.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@swimlane/ngx-charts": "^21.0.0",
    "angular-animations": "^0.11.0",
    "hammerjs": "^2.0.8",
    "katex": "^0.16.11",
    "ngx-bar-rating": "^8.0.1",
    "ngx-colors": "^3.6.0",
    "ngx-markdown": "^19.0.0",
    "ngx-sse-client": "^17.0.0",
    "rxjs": "~7.8.0",
    "safe-pipe": "^3.0.4",
    "tslib": "^2.3.0",
    "zone.js": "~0.15.0"
  },
  "overrides": {
    "@swimlane/ngx-charts": {
      "@angular/animations@": ">=18.0.0",
      "@angular/cdk@": ">=18.0.0",
      "@angular/common@": ">=18.0.0",
      "@angular/core@": ">=18.0.0",
      "@angular/forms@": ">=18.0.0",
      "@angular/platform-browser@": ">=18.0.0",
      "@angular/platform-browser-dynamic@": ">=18.0.0",
      "@angular/router@": ">=18.0.0"
    }
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.1.4",
    "@angular/cli": "^19.1.4",
    "@angular/compiler-cli": "^19.1.3",
    "@angular/localize": "^19.1.3",
    "@ngrx/schematics": "^19.0.0",
    "@types/jasmine": "~5.1.0",
    "@types/uuid": "^10.0.0",
    "changelog-parser": "^3.0.1",
    "esbuild-visualizer": "^0.7.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "pandoc": "^0.2.0",
    "typescript": "~5.7.3"
  },
  "engines": {
    "node": ">=20.0.0"
  }
}
