<div class="admin-header"><div>{{ 'PROMPTLAB.TITLE' | translate }}</div><div><button mat-flat-button (click)="addPrompt()">
    <mat-icon>add_circle</mat-icon>
    {{ 'PROMPTLAB.ADD_PROMPT' | translate }}
</button></div></div>

<mat-accordion>

    @for (prompt of prompts$ | async; track prompt.id; let i = $index) {
        <mat-expansion-panel [expanded]="i==0">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon [ngStyle]="{ color: getForm(prompt).value.appearance}" >
                        {{environment.app.defaultPromptAppearance.icon}}
                    </mat-icon>&nbsp;
                        {{ getForm(prompt).value.title }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <form [formGroup]="getForm(prompt)" xmlns="http://www.w3.org/1999/html">
                <div class="prompt-form">
                    <div class="row">
                        <div class="row-fill">
                            <mat-form-field appearance="outline" subscriptSizing="dynamic" style="width: 100%">
                                <mat-label>{{ 'PROMPTLAB.FORM.TITLE' | translate }}</mat-label>
                                <div class="row">
                                    <div class="row-fill">
                                        <input matInput type="text" formControlName="title">
                                    </div>
                                    <div class="row-min">
                                        <ngx-colors
                                                ngx-colors-trigger
                                                [acceptLabel]="'GENERIC.SELECT' | translate"
                                                [cancelLabel]="'GENERIC.CANCEL' | translate"
                                                style="display: inline-block; margin:5px;"
                                                [hideTextInput]="true"
                                                colorPickerControls="no-alpha"
                                                formControlName="appearance">
                                        </ngx-colors>
                                    </div>
                                </div>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="row-fill">
                            <mat-form-field appearance="outline" style="width: 100%" subscriptSizing="dynamic">
                                <mat-label>{{'PROMPTLAB.FORM.GOAL_DESCRIPTION' | translate}}</mat-label>
                                <textarea
                                        cdkTextareaAutosize
                                        #autosize="cdkTextareaAutosize"
                                        cdkAutosizeMinRows="10"
                                        cdkAutosizeMaxRows="50"
                                        matInput type="text" formControlName="goalDescription"></textarea>
                                <mat-icon matSuffix
                                          (mouseover)="showPromptHint = true"
                                          (mouseout)="showPromptHint = false">info</mat-icon>
                                <img src="img/admin-promptlab-prompt-example-de.png"
                                     *ngIf="showPromptHint"
                                     class="overlay-image">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="row-fill">
                            <mat-form-field appearance="outline" style="width: 100%" subscriptSizing="dynamic">
                                <mat-label>{{'PROMPTLAB.FORM.AVAILABLE_TOPICS' | translate}}</mat-label>
                                <mat-select formControlName="availableTopics" multiple>
                                    @for (topic of tenantTopics$ | async; track topic) {
                                        <mat-option [value]="topic">{{topic}}</mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                        </div>
                        </div>
                    <div class="row">
                        <div class="row-fill">
                            <mat-form-field appearance="outline" style="width: 100%" subscriptSizing="dynamic">
                                <mat-label>{{'PROMPTLAB.FORM.DEFAULT_TOPICS' | translate}}</mat-label>
                                <mat-select formControlName="defaultEnabledTopics" multiple>
                                    @for (topic of getForm(prompt).value.availableTopics; track topic) {
                                        <mat-option [value]="topic">{{topic}}</mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="row-min">
                    <mat-form-field appearance="outline" subscriptSizing="dynamic">
                        <mat-label>{{'PROMPTLAB.FORM.LLM' | translate}}</mat-label>
                        <mat-select formControlName="llm">
                            @for (llm of llms$ | async; track llm) {
                                <mat-option [value]="llm">
                                    {{ llm }}
                                </mat-option>
                            }
                        </mat-select>
                    </mat-form-field>
                        </div>
                        <div class="row-min">
                            <mat-slide-toggle formControlName="grounding">{{'PROMPTLAB.FORM.GROUNDING' | translate}}</mat-slide-toggle>
                        </div>

                            <div class="row-min">
                            <mat-form-field appearance="outline" subscriptSizing="dynamic">
                                <mat-label>{{'PROMPTLAB.FORM.ACCESSIBLE_BY' | translate}}</mat-label>
                                <mat-select formControlName="accessibleBy">
                                    @for (userLevel of userLevels; track userLevel.tier) {
                                        <mat-option [value]="userLevel.tier">
                                            {{ userLevel.name }}
                                        </mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                            </div>
                            <div class="row-min">
                            <mat-form-field appearance="outline" subscriptSizing="dynamic">
                                <mat-label>{{'PROMPTLAB.FORM.USED_BY' | translate}}</mat-label>
                                <mat-select formControlName="usedBy">
                                    @for (userOption of userOptions; track userOption.tier) {
                                        <mat-option [value]="userOption.tier">
                                            {{ userOption.name }}
                                        </mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                            </div>
                            <div class="row-min">
                                <mat-slide-toggle formControlName="default">{{'PROMPTLAB.FORM.DEFAULT' | translate}}</mat-slide-toggle>
                            </div>
                            <div class="row-min">
                                <mat-slide-toggle formControlName="schema_out_enabled">{{'PROMPTLAB.FORM.SCHEMA_OUT' | translate}}</mat-slide-toggle>
                            </div>
                    </div>
                    <div class="row" *ngIf="getForm(prompt).value.schema_out_enabled">
                        <div class="row-fill">
                            <mat-form-field appearance="outline" style="width: 100%" subscriptSizing="dynamic">
                                <mat-label>{{'PROMPTLAB.FORM.SCHEMA_OUT_VALUE' | translate}}</mat-label>
                                <textarea
                                        cdkTextareaAutosize
                                        #autosize="cdkTextareaAutosize"
                                        cdkAutosizeMinRows="3"
                                        cdkAutosizeMaxRows="10"
                                        matInput formControlName="schema_out"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </form>
            <div class = "prompt-label">
                {{'PROMPTLAB.FORM.LABEL' | translate}}: {{ prompt.label }}
            </div>
            <mat-action-row>
                        <button mat-flat-button (click)="deletePrompt(prompt)" class="delete-button">
                            <mat-icon>delete</mat-icon>
                            {{'PROMPTLAB.FORM.DELETE' | translate}}
                        </button>
                @if (prompt.unsaved){
                        <button mat-flat-button (click)="savePrompt(getForm(prompt), prompt)" [disabled]="!getForm(prompt).valid">
                            <mat-icon>check</mat-icon>
                            {{'PROMPTLAB.FORM.SAVE' | translate}}
                        </button>
                        } @else {
                    <button mat-flat-button (click)="updatePrompt(getForm(prompt), prompt)" [disabled]="!getForm(prompt).valid">
                        <mat-icon>check</mat-icon>
                        {{'PROMPTLAB.FORM.SAVE_CHANGES' | translate}}
                    </button>
                }
            </mat-action-row>
        </mat-expansion-panel>

    }
</mat-accordion>

