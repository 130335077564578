<mat-card  appearance="outlined" class="chat-llm-answer-card">
    <mat-card-content class="chat-llm-answer">
        <div class="chat-llm-answer-text" *ngIf="text != undefined">
            <markdown [data]="(text | async)?.chunk_message">
            </markdown>
        </div>
        <div class="chat-llm-answer-text" *ngIf="_element != undefined">
            <markdown katex  [katexOptions]="katexOptions">
                {{sanitizeContent(_element.answer)}}
            </markdown>
            <div class="answer-metadata">
            <div>
                <button mat-icon-button class="control-button" (mouseover)="toggleInfo()" *ngIf="infoEnabled" (mouseout)="toggleInfo()"><mat-icon>info</mat-icon></button>
            </div>
            <div *ngIf="showInfo" class="answer-info-container">
                <div class="answer-info-row">
                    <mat-icon inline [ngStyle]="{color: (getPrompt(_element.prompt?.label) |async)?.appearance?.color || 'gray'}">{{ (activePrompt$ | async)!.appearance.icon }}</mat-icon> {{_element.prompt?.title || 'ERROR.UNKNOWN_PROMPT' | translate}}
                    <span *ngIf="false"><mat-icon inline>token</mat-icon> {{_element?.total_tokens}}</span>

                </div>
            </div>
            <div *ngIf="showFeedback" class="answer-rating-container">
                <form [formGroup]="feedbackForm" class="answer-rating-form" (ngSubmit)="changeRating(_element.id)">
                    <bar-rating
                            [rate]="_element.feedback?.rating || 1" [max]="6"
                            [theme]="'square'"
                            [showText]="true"
                            [formControlName]="'rating'"
                            [titles]="['FEEDBACK.0' | translate, 'FEEDBACK.1' | translate, 'FEEDBACK.2' | translate, 'FEEDBACK.3' | translate, 'FEEDBACK.4' | translate, 'FEEDBACK.5' | translate, 'FEEDBACK.6' | translate]"
                            effect
                    ></bar-rating>
                    <mat-form-field appearance="outline">
                        <textarea matInput formControlName="comment" placeholder="{{'FEEDBACK.COMMENT_PLACEHOLDER' | translate}}">
                            {{_element.feedback?.comment}}
                        </textarea>
                    </mat-form-field>
                    <button type="submit"
                            [disabled]="!feedbackForm.valid"  mat-raised-button>{{'FEEDBACK.SUBMIT' | translate}}</button>
                </form>
            </div>
            </div>

        </div>
        <div class="chat-llm-answer-controls">
            <div class="control-copy" ><button *ngIf="_element != undefined" mat-icon-button class="control-button" (click)="copyClipboard(_element.answer)"><mat-icon>content_copy</mat-icon></button></div>
            <div class="control-streaming"><mat-spinner *ngIf="ongoing" diameter="16" strokeWidth="2"></mat-spinner></div>
            <div class="control-sources">
                <button *ngIf="feedbackEnabled" mat-icon-button class="control-button" (click)="toggleFeeback()">
                    <mat-icon>thumbs_up_down</mat-icon>
                </button>
            </div>

        </div>
    </mat-card-content>
    <mat-card-footer *ngIf="_element != undefined">
        <mat-chip-set>
            @for (group of groupByTopic(_element.documents) | keyvalue; track group.key;) {
                <mat-basic-chip (click)="toggleSourceTopic(_element.id + group.key)" matBadgePosition="above after" [matBadge]="castToRetrievedDocument(group.value).length">{{mapTopicName((activeTenant$ | async)?.topics || [], group.key) }}</mat-basic-chip>
            }
        </mat-chip-set>
        @for (group of groupByTopic(_element.documents) | keyvalue; track group.key) {
            <div [id]="_element.id + group.key" [hidden]="visibleSourceTopic !== _element.id + group.key" >
                <mat-nav-list>
                    @for(source of castToRetrievedDocument(group.value); track source; let idx = $index) {
                        <a mat-list-item href="{{source.topic == 'AD_HOC_UPLOAD' ? '#' : source.url}}" [target]="source.topic == 'AD_HOC_UPLOAD' ? '_self' : '_blank'"><div *ngIf="source.topic !== 'AD_HOC_UPLOAD'" matListItemMeta><mat-icon>open_in_new</mat-icon></div>{{(idx+1) + ". " + source.title}}</a>
                    }
                </mat-nav-list>
            </div>

        }
    </mat-card-footer>
</mat-card>
