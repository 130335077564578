import {createReducer, on} from '@ngrx/store';
import {AppActions} from '../actions/app.actions';
import {AccountInfo} from "@azure/msal-browser";
import {SafeUrl} from "@angular/platform-browser";
import {ErrorMessage, ErrorMessageType} from "../../model/error-message.model";
import {ConversationsActions} from "../actions/conversations.actions";
import {GroupActions} from "../actions/group.actions";
import {Limits} from "../../model/group.model";
import {LimitsDTO, PermissionDTO} from "../../model/dto.model";
import {PermissionActions} from "../actions/permission.actions";
import {State} from "./index";
import {Permission} from "../../model/permissions.model";
import {mapToPromptDTO} from "./prompt-lab.reducer";

export const permissionsPrompt = (state: State) => state.permissions.permissions;


export interface PermissionState {
    permissions: Permission[];

}


export const initialState: PermissionState = {
    permissions: []
};

export const reducer = createReducer(
  initialState,
    on(PermissionActions.loadPermissionsSuccess, (state, data) => ({...state, permissions: mapToPermissionObjectArray(data.data)})),
    );


export const mapToPermissionObjectArray = function(permissions: PermissionDTO[]): Permission[] {
    return permissions.map(mapToPermissionObject);
}

export const mapToPermissionObject = function(permission: PermissionDTO): Permission {
    return {
        email: permission.email,
        tier: permission.tier,
        isEditMode: false
    }
}

export const mapToPermissionDTO = function(permission: Permission): PermissionDTO {
    return {
        email: permission.email,
        tier: permission.tier
    }
}
