import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Prompt, PromptAppearance } from "../../model/prompt.model";
import { mapToPromptDTO, tenantLLMs, tenantPrompts, tenantTopics } from "../../redux/reducers/prompt-lab.reducer";
import { PromptLabActions } from "../../redux/actions/prompt-lab.actions";
import { Store } from "@ngrx/store";
import { State } from "../../redux/reducers";
import { activeTenant } from "../../redux/reducers/group.reducer";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../../../environments/environment";

@Component({
    selector: 'app-admin-promptlab',
    templateUrl: './admin-promptlab.component.html',
    styleUrl: './admin-promptlab.component.scss',
    standalone: false
})
export class AdminPromptlabComponent implements OnInit {



    forms: IHash = {};
    llms$ = this.store.select(tenantLLMs);
    styles: PromptAppearance[] = [
        { color: 'red', icon: 'auto_awesome' },
        { color: 'orange', icon: 'auto_awesome' },
        { color: 'green', icon: 'auto_awesome' },
        { color: 'blue', icon: 'auto_awesome' }
    ];
    tenantTopics$ = this.store.select(tenantTopics);
    prompts$ = this.store.select(tenantPrompts);
    userLevels = [{ tier: 'users', name: 'Tenant Users' }, { tier: 'moderators', name: 'Moderators' }, { tier: 'superusers', name: 'Super Users' }, { tier: 'admins', name: 'Admins' }];
    userOptions = [{ tier: 'users', name: 'Humans' }, { tier: 'machines', name: 'Machines' }, { tier: 'all', name: 'All' }];
    showPromptHint = false;

    constructor(private fb: FormBuilder, private store: Store<State>, private translate: TranslateService) {

    }

    ngOnInit() {
        this.store.select(activeTenant).subscribe(
            tenant => {
                if (tenant) {
                    this.store.dispatch(PromptLabActions.loadPromptLab());
                    this.store.dispatch(PromptLabActions.loadPrompts());
                }
            }
        )
    }

    addPrompt() {
        this.store.dispatch(PromptLabActions.createNewPrompt())
    }

    deletePrompt(p: Prompt) {
        this.store.dispatch(PromptLabActions.deletePrompt({ prompt: mapToPromptDTO(p) }));
    }

    updatePrompt(form: FormGroup, initialPrompt: Prompt) {
        let prompt = {} as Prompt;
        prompt.availableTopics = form.value.availableTopics;
        prompt.defaultEnabledTopics = form.value.defaultEnabledTopics;
        prompt.title = form.value.title;
        prompt.label = initialPrompt.label;
        prompt.appearance = { icon: 'auto_awesome', color: form.value.appearance };
        prompt.accessibleBy = form.value.accessibleBy;
        prompt.usedBy = form.value.usedBy;
        prompt.llm = form.value.llm;
        prompt.goalDescription = form.value.goalDescription;
        prompt.grounding = form.value.grounding;
        prompt.default = form.value.default;
        
        // Handle schema_out - use default value when enabled but empty
        if (form.value.schema_out_enabled) {
            if (!form.value.schema_out || form.value.schema_out === '{}') {
                prompt.schema_out = '{"title": "title", "description": "description", "type": "object", "properties": {}}';
            } else {
                prompt.schema_out = form.value.schema_out;
            }
        } else {
            prompt.schema_out = '{}';
        }
        
        // Ensure it's a string
        if (typeof prompt.schema_out === 'object') {
            prompt.schema_out = JSON.stringify(prompt.schema_out);
        }
        
        this.store.dispatch(PromptLabActions.savePrompt({ prompt: mapToPromptDTO(prompt) }));
    }

    savePrompt(form: FormGroup, initialPrompt: Prompt) {
        let prompt = {} as Prompt;
        prompt.availableTopics = form.value.availableTopics;
        prompt.defaultEnabledTopics = form.value.defaultEnabledTopics;
        prompt.title = form.value.title;
        prompt.label = 'new_prompt'
        prompt.appearance = { icon: 'auto_awesome', color: form.value.appearance };
        prompt.accessibleBy = form.value.accessibleBy;
        prompt.usedBy = form.value.usedBy;
        prompt.llm = form.value.llm;
        prompt.goalDescription = form.value.goalDescription;
        prompt.grounding = form.value.grounding;
        prompt.default = form.value.default;
        
        // Handle schema_out - use default value when enabled but empty
        if (form.value.schema_out_enabled) {
            if (!form.value.schema_out || form.value.schema_out === '{}') {
                prompt.schema_out = '{"title": "title", "description": "description", "type": "object", "properties": {}}';
            } else {
                prompt.schema_out = form.value.schema_out;
            }
        } else {
            prompt.schema_out = '{}';
        }
        
        // Ensure it's a string
        if (typeof prompt.schema_out === 'object') {
            prompt.schema_out = JSON.stringify(prompt.schema_out);
        }
        
        this.store.dispatch(PromptLabActions.saveNewPrompt({ prompt: mapToPromptDTO(prompt) }));
    }

    getForm(p: Prompt) {
        if (!this.forms[p.id]) {
            // Ensure schema_out is properly stringified if it's an object
            let schemaOutValue = p.schema_out || '{}';
            if (typeof schemaOutValue === 'object') {
                schemaOutValue = JSON.stringify(schemaOutValue, null, 2);
            }
            
            // Determine if schema_out is enabled based on its value
            const isSchemaOutEnabled = schemaOutValue && schemaOutValue !== '{}' && schemaOutValue !== '';

            this.forms[p.id] = this.fb.group({
                title: [p.title, [Validators.minLength(5), Validators.maxLength(50), Validators.required, Validators.pattern(/^[a-zA-Z0-9_\-\s]*$/)]],
                appearance: [p.appearance.color || 'red', Validators.required],
                accessibleBy: [p.accessibleBy || 0, Validators.required],
                usedBy: [p.usedBy],
                availableTopics: [p.availableTopics],
                defaultEnabledTopics: [p.defaultEnabledTopics],
                llm: [p.llm, Validators.required],
                goalDescription: [p.goalDescription, [Validators.required, Validators.minLength(5), Validators.maxLength(4000)]],
                grounding: p.grounding || false,
                default: [p.default || false, Validators.required],
                schema_out_enabled: [isSchemaOutEnabled],
                schema_out: [schemaOutValue || '{"title": "title", "description": "description", "type": "object", "properties": {}}']
            });

            // Listen for changes in the schema_out_enabled control
            this.forms[p.id].get('schema_out_enabled')?.valueChanges.subscribe(enabled => {
                if (!enabled) {
                    // If disabled, set schema_out to empty object
                    this.forms[p.id].get('schema_out')?.setValue('{}');
                } else if (this.forms[p.id].get('schema_out')?.value === '{}' || !this.forms[p.id].get('schema_out')?.value) {
                    // If enabled and schema_out is empty, set it to the default JSON object
                    this.forms[p.id].get('schema_out')?.setValue('{"title": "title", "description": "description", "type": "object", "properties": {}}');
                }
            });
        }
        return this.forms[p.id];
    }

    protected readonly environment = environment;
}



interface IHash {
    [details: string]: FormGroup;
}


