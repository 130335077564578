import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {GroupActions} from "../actions/group.actions";
import {catchError, map, of, switchMap} from "rxjs";
import {RestService} from "../../service/rest.service";
import {Tenant} from "../../model/group.model";
import {ConversationStub} from "../../model/conversation.model";
import {AppActions} from "../actions/app.actions";
import {ConversationsActions} from "../actions/conversations.actions";
import {convertTenantDTO, PromptDTO, TenantDTO} from "../../model/dto.model";
import {mapToPromptObject} from "../reducers/prompt-lab.reducer";
import {Prompt} from "../../model/prompt.model";


@Injectable()
export class GroupEffects {


  constructor(private actions$: Actions, private restService: RestService) {}

  loadGroups$ = createEffect(() => this.actions$.pipe(
    ofType(GroupActions.groupsLoad),
    switchMap(() => this.restService.get<TenantDTO[]>("/v0/user/group-accesses").pipe(
      map(groups => {
          if (groups.length === 0) {
              return GroupActions.groupsEmpty();
          } else {
              return GroupActions.groupsLoadSuccess({data: groups.map(g => convertTenantDTO(g))});
          }
      }),
      catchError(error => of(GroupActions.groupsLoadFailure({error})))
    ))
  ));

    loadPrompts$ = createEffect(() => this.actions$.pipe(
        ofType(GroupActions.groupsActivate),
        switchMap((data) => this.restService.get<PromptDTO[]>(`/v0/tenants/${data.tenant.id}/prompts/personal`).pipe(
            map(prompts => GroupActions.groupsLoadPromptsSuccess({prompts: prompts.map(p => mapToPromptObject(p))})),
            catchError(error => of(GroupActions.groupsLoadPromptsFailure({error: error})))
        ))
    ));

    activateDefaultPrompt$ = createEffect(() => this.actions$.pipe(
        ofType(GroupActions.groupsLoadPromptsSuccess),
        map((p) => GroupActions.groupsActivatePrompt({prompt: this.getDefaultPrompt(p.prompts)}))
    ));

  updateAppTitle$ = createEffect(() => this.actions$.pipe(
    ofType(GroupActions.groupsActivate),
    map(a => AppActions.appUpdateTitle({title: a.tenant.name}))
  ));

     // We don't need this method anymore as the conversationsLoad effect already handles this functionality with the new API
     // This method is kept as reference but will not be executed
     /*
     getConversationList = createEffect(() => this.actions$.pipe(
         ofType(GroupActions.groupsActivate),
         switchMap((activation) => this.restService.get<ConversationStub[]>(`/v0/tenants/${activation.tenant.id}/conversations`).pipe(
             map(conversations => {
                 let latestConversation = this.findLatestConversation(conversations);
                 let requireNewConversation = this.findLatestEmptyConversation(conversations) === undefined;
                 return ConversationsActions.conversationsLoadSuccess({
                     data: conversations, 
                     latestConversationId: latestConversation?.id, 
                     requireNewConversation: requireNewConversation,
                     hasMore: false,
                     cursor: undefined
                 });
                 }
             ),
             catchError(error => {
                 return of(ConversationsActions.conversationsLoadFailure({error}));})
         ))
     ));
     */

    findLatestEmptyConversation(conversations: ConversationStub[]): ConversationStub | undefined {
        if (conversations == undefined || conversations.length == 0) {
            return undefined;
        }
        conversations.sort((a,b) => new Date(b.modified_at).getTime() - new Date(a.modified_at).getTime());
        if(conversations[0].message_count !== 0 && new Date(conversations[0].modified_at).getTime() < new Date().getTime() - 1000 * 60 * 60 * 4) {
            return undefined;
        } else {
            return conversations[0];
        }
    }

    findLatestConversation(conversations: ConversationStub[]): ConversationStub | undefined {
        if (conversations == undefined || conversations.length == 0) {
            return undefined;
        }
        conversations.sort((a,b) => new Date(b.modified_at).getTime() - new Date(a.modified_at).getTime());
        return conversations[0];

    }

    getDefaultPrompt(prompts: Prompt[]): Prompt {
         let def = prompts.find(p => p.default);
         if (def == undefined) {
             return prompts[0] //if no default prompt is set, return the first prompt
         } else {
             return def;
         }
    }


}
