import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {GroupActions} from "../actions/group.actions";
import {catchError, map, of, switchMap, withLatestFrom} from "rxjs";
import {ConversationsActions} from "../actions/conversations.actions";
import {RestService} from "../../service/rest.service";
import {Conversation, ConversationStub} from "../../model/conversation.model";
import {Store} from "@ngrx/store";
import {State} from "../reducers";
import * as fromConversations from '../reducers/conversations.reducer';
import * as fromGroups from '../reducers/group.reducer';

import {Tenant} from "../../model/group.model";
import {SseService} from "../../service/sse.service";
import {ChatMessage, Document, Rating} from "../../model/chat-message";
import {AppActions} from "../actions/app.actions";
import {ConversationDTO, convertConversationDTO, convertRating, RatingDTO} from "../../model/dto.model";
import {environment} from "../../../environments/environment";



@Injectable()
export class ConversationsEffects {


  constructor(private actions$: Actions, private restService: RestService, private sseService: SseService, private store: Store<State>) {}

    loadConversations$ = createEffect(() => this.actions$.pipe(
        ofType(GroupActions.groupsActivate),
        map((data) => ConversationsActions.conversationsLoad({
            groupId: data.tenant.id, 
            limit: environment.app.conversations.initialCount
        }))
    ));
    
    loadMoreConversations$ = createEffect(() => this.actions$.pipe(
        ofType(ConversationsActions.conversationsLoadMore),
        withLatestFrom(this.store.select((state) => state.conversations.conversations)),
        switchMap(([data, existingConversations]) => {
            // Load more conversations
            
            // Make sure to encode the cursor to handle special characters in ISO date strings
            const encodedCursor = encodeURIComponent(data.cursor);
            
            return this.restService.get<ConversationStub[]>(
                `/v0/tenants/${data.groupId}/conversations?limit=${data.limit}&cursor=${encodedCursor}`
            ).pipe(
                map(newConversations => {
                    // Process the newly loaded conversations
                    
                    // Keep the first conversation as the latest (for navigation)
                    const latestConversation = existingConversations.length > 0 ? existingConversations[0].id : undefined;
                    
                    // Get the cursor from the last conversation of the new batch
                    // Extract just the ISO date string without any additional characters
                    let lastConversation;
                    if (newConversations.length > 0) {
                        const rawDate = newConversations[newConversations.length-1].modified_at;
                        try {
                            // Parse and reformat to ensure valid ISO format
                            const dateObj = new Date(rawDate);
                            if (!isNaN(dateObj.getTime())) {
                                lastConversation = dateObj.toISOString();
                            } else {
                                console.error('Invalid date format received:', rawDate);
                                lastConversation = undefined;
                            }
                        } catch (e) {
                            console.error('Error formatting cursor date:', e);
                            lastConversation = undefined;
                        }
                    } else {
                        lastConversation = undefined;
                    }
                    
                    // Combine existing and new conversations, ensuring no duplicates
                    const existingIds = new Set(existingConversations.map(c => c.id));
                    const uniqueNewConversations = newConversations.filter(c => !existingIds.has(c.id));
                    
                    // Determine if there are more conversations to load
                    const hasMore = newConversations.length >= data.limit;
                    
                    // Send only the new conversations to the reducer
                    // The reducer will append them to the existing list based on isLoadingMore state
                    return ConversationsActions.conversationsLoadSuccess({
                        data: uniqueNewConversations, 
                        latestConversationId: latestConversation,
                        requireNewConversation: existingConversations.length === 0 && newConversations.length === 0,
                        hasMore: hasMore,
                        cursor: lastConversation
                    });
                }),
                catchError(error => {
                    console.error('Error loading more conversations:', error);
                    return of(ConversationsActions.conversationsLoadFailure({error: error}));
                })
            );
        })
    ));

    // Effect that actually loads conversations - handles initial load and pagination
    conversationsLoad = createEffect(() => this.actions$.pipe(
        ofType(ConversationsActions.conversationsLoad),
        switchMap((data) => {
            let url = `/v0/tenants/${data.groupId}/conversations`;
            const params = [];
            
            // Always include limit parameter for initial load
            const actualLimit = data.limit || environment.app.conversations.initialCount;
            params.push(`limit=${actualLimit}`);
            
            if (data.cursor) {
                // Ensure cursor is properly encoded for URL
                const encodedCursor = encodeURIComponent(data.cursor);
                params.push(`cursor=${encodedCursor}`);
            }
            
            if (params.length > 0) {
                url += `?${params.join('&')}`;
            }
            
            // Initial load of conversations
            
            return this.restService.get<ConversationStub[]>(url).pipe(
                map(conversations => {
                    // Process the initially loaded conversations
                    
                    const latestConversation = conversations.length > 0 ? conversations[0].id : undefined;
                    
                    // Format the cursor date properly to ensure ISO format
                    let lastConversation;
                    if (conversations.length > 0) {
                        const rawDate = conversations[conversations.length-1].modified_at;
                        try {
                            // Parse and reformat to ensure valid ISO format
                            const dateObj = new Date(rawDate);
                            if (!isNaN(dateObj.getTime())) {
                                lastConversation = dateObj.toISOString();
                            } else {
                                console.error('Invalid date format received in initial load:', rawDate);
                                lastConversation = undefined;
                            }
                        } catch (e) {
                            console.error('Error formatting cursor date in initial load:', e);
                            lastConversation = undefined;
                        }
                    } else {
                        lastConversation = undefined;
                    }
                    
                    // Determine if there are more conversations to load
                    const hasMore = conversations.length >= actualLimit;
                    
                    // Prepare success result with conversation data
                    
                    return ConversationsActions.conversationsLoadSuccess({
                        data: conversations, 
                        latestConversationId: latestConversation,
                        requireNewConversation: conversations.length === 0,
                        hasMore: hasMore,
                        cursor: lastConversation
                    });
                }),
                catchError(error => {
                    console.error('Error loading conversations:', error);
                    return of(ConversationsActions.conversationsLoadFailure({error: error}));
                })
            );
        })
    ));

    deleteConversation = createEffect(() => this.actions$.pipe(
        ofType(ConversationsActions.conversationDelete),
        withLatestFrom(this.store.select(fromGroups.selectActiveGroupId)),
        switchMap(([data, groupId]) => this.restService.delete<Tenant[]>(`/v0/tenants/${groupId}/conversations/${data.conversationId}`).pipe(
            map(groups => GroupActions.groupsLoadSuccess({data: groups})),
            catchError(error => of(GroupActions.groupsLoadFailure({error: error})))
        ))
    ));

    addConversation = createEffect(() => this.actions$.pipe(
        ofType(ConversationsActions.conversationNew),
        switchMap((data) => this.restService.post<ConversationStub>(`/v0/tenants/${data.groupId}/conversations`, {}).pipe(
            switchMap(
            conv => of(ConversationsActions.conversationNewSuccess({conversation: conv}), ConversationsActions.conversationActivate({conversationId: conv.id}))),
            catchError(error => of(ConversationsActions.conversationNewFailure({error: error})))
        ))
    ));

    loadConversationDetail = createEffect(() => this.actions$.pipe(
        ofType(ConversationsActions.conversationActivate),
        withLatestFrom(this.store.select(fromGroups.selectActiveGroupId)),
        switchMap(([data, groupId]) => this.restService.get<ConversationDTO>(`/v0/tenants/${groupId}/conversations/${data.conversationId}/messages`).pipe(
            map(
                conv => ConversationsActions.conversationDetailLoadSuccess({data: convertConversationDTO(conv)})),
            catchError(error => of(ConversationsActions.conversationDetailLoadFailure({error: error, action: "CONV_ACTIVATE"})))
        ))
    ));

    setBusyState = createEffect(() => this.actions$.pipe(
        ofType(ConversationsActions.conversationDetailAddQuestion),
        map(() => AppActions.appBusy())
    ));

    unsetBusyState = createEffect(() => this.actions$.pipe(
        ofType(ConversationsActions.conversationDetailAddFinalResponse),
        map(() => AppActions.appIdle())
    ));

    addQuestion = createEffect(() => this.actions$.pipe(
        ofType(ConversationsActions.conversationDetailAddQuestion),
        withLatestFrom(this.store.select(fromGroups.selectActiveGroupId), this.store.select(fromConversations.selectActiveConversationId), this.store.select(fromGroups.activeTopics), this.store.select(fromGroups.activePrompt)),
        switchMap ((data) =>  this.sseService.queryLLM(data[1] || "", data[2] || "", data[3], data[4]?.label || "", data[0].question).pipe(
            map((event: ChatMessage | ConversationStub) => {
                if ('ongoing' in event) {
                    //chat update
                    const message = event as ChatMessage;
                    if (message.ongoing || 'chunk_message' in message) {
                        return ConversationsActions.conversationDetailAddResponseChunk({response_chunk: message})
                    } else {
                        return ConversationsActions.conversationDetailAddFinalResponse({response: message})
                    }

                } else {
                    //conversation update
                    return ConversationsActions.conversationRenameSuccess({conversationId: event.id, newTitle: event.title})
                }
            }
                )
        ))

    ));

    renameConversation = createEffect(() => this.actions$.pipe(
        ofType(ConversationsActions.conversationRename),
        withLatestFrom(this.store.select(fromGroups.selectActiveGroupId)),
        switchMap(([data, groupId]) => this.restService.put<ConversationStub>(`/v0/tenants/${groupId}/conversations/${data.conversationId}`, {title: data.newTitle}).pipe(
            map(conv => ConversationsActions.conversationRenameSuccess({conversationId: conv.id, newTitle: conv.title})),
            catchError(error => of(ConversationsActions.conversationRenameFailure({error: error})))
        ))
    ));

    uploadFile = createEffect(() => this.actions$.pipe(
        ofType(ConversationsActions.conversationUploadFile),
        withLatestFrom(this.store.select(fromGroups.selectActiveGroupId)),
        switchMap(([data, groupId]) =>
            this.restService.post<Document>(`/v0/tenants/${groupId}/conversations/${data.conversationId}/documents?classification=${data.classification}`, data.file)
        .pipe(
            map(
                doc => ConversationsActions.conversationUploadFileSuccess({conversationId: data.conversationId, fileId: doc.id})),
            catchError(error => of(ConversationsActions.conversationUploadFileFailure({error: error.error})))
        ))
    ));

    uploadFileSuccess = createEffect(() => this.actions$.pipe(
        ofType(ConversationsActions.conversationUploadFileSuccess, ConversationsActions.conversationUploadFileFailure, ConversationsActions.conversationDeleteFileSuccess),
        withLatestFrom(this.store.select(fromConversations.selectActiveConversationId)),
        switchMap(([data, conversationId]) =>
            of(ConversationsActions.conversationActivate({conversationId: conversationId || ""})))
    ));

    deleteFile = createEffect(() => this.actions$.pipe(
        ofType(ConversationsActions.conversationDeleteFile),
        withLatestFrom(this.store.select(fromGroups.selectActiveGroupId)),
        switchMap(([data, groupId]) => this.restService.delete<Document>(`/v0/tenants/${groupId}/conversations/${data.conversationId}/documents/${data.fileId}`).pipe(
            map(doc => ConversationsActions.conversationDeleteFileSuccess({fileId: data.fileId})),
            catchError(error => of(ConversationsActions.conversationDeleteFileFailure({error: error.error}))
            )
        ))
    ));

    sendFeedback = createEffect(() => this.actions$.pipe(
        ofType(ConversationsActions.rateAnswer),
        withLatestFrom(this.store.select(fromGroups.selectActiveGroupId)),
        switchMap(([data, groupId]) => this.restService.post<RatingDTO>(`/v0/tenants/${groupId}/conversations/${data.conversationId}/messages/${data.rating.elementId}/feedback`, convertRating(data.rating)).pipe(
            map(() => ConversationsActions.rateAnswerSuccess({rating: data.rating})),
            catchError(error => of(AppActions.appGenericError({error: error.error}))
            )
        ))
    ));


}
